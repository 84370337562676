<template>
  <div class="c4-passwordbox">
    <input
      class="w3-input w3-border"
      :class="{ 'w3-border-red': invalid }"
      :type="showPassword ? 'text' : 'password'"
      :name="name"
      :value="this.modelValue !== undefined ? this.modelValue : this.value"
      :pattern="pattern"
      :autocomplete="autocomplete"
      :required="required"
      :disabled="disabled"
      :title="title"
			:id="id"
      @input="handleInput"
    >
    <span
      role="button"
      :title="buttonTitle"
      @click="showPassword = !showPassword"
    >
      <i class="far" :class="showPassword ? 'fa-eye' : 'fa-eye-slash'"></i>
    </span>
  </div>
</template>

<script>
export default {
  name: "PasswordInput",
  props: {
		id: String,
    name: String,
		value: String,
    modelValue: String,
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    invalid: {
      type: Boolean,
      default: false,
    },
    pattern: String,
    title: String,
    buttonTitle: String,
    autocomplete: String,
  },
  data() {
    return {
      showPassword: false,
    };
  },
  methods: {
    handleInput(e) {
      this.$emit("update:modelValue", e.target.value);
    },
  },
};
</script>

<style lang="scss">
.c4-passwordbox {
  & > input {
    display: inline-block;
    padding-right: 2.25rem;
  }
  & > span {
    margin-left: -1.75rem;
    cursor: pointer;
  }
}
</style>