import { createWebHistory, createRouter } from "vue-router";
import auth from "@/auth.js"
import C4Portal from "@/views/C4Portal.vue";
import C4Wizard from "@/views/C4Wizard.vue";
import C4Mailbox from "@/views/C4Mailbox.vue";
import LoginBox from "@/components/LoginBox.vue";
import Error502 from "@/components/error/502.vue";
import Error404 from "@/components/error/404.vue";
import {
  i18n,
  setI18nLanguage,
  loadLocaleMessages,
  SUPPORT_LOCALES,
  matchLocale,
} from "@/i18n";


async function requireAuth(to, from, next) {
  const res = await auth.loggedIn();
  // console.log("Should redirect, logged in", res);
  if (!res) {
    // console.log("Not logged in");
    next({
      path: '/login',
      query: { redirect: to.fullPath }
    })
  } else {
    next()
  }
}

const routes = [
  {
    path: "/",
    name: "Home",
    component: C4Portal,
  },
  {
    path: "/portal",
    name: "Portal",
    component: C4Portal,
  },
  {
    path: "/case",
    name: "Case",
    component: C4Wizard,
    beforeEnter(to, from, next) {
      if (!to.query.refId) {
        next("/login");
      }
      else {
        next();
      }
    },
  },
  {
    path: "/inbox",
    name: "Inbox",
    component: C4Mailbox,
    beforeEnter: requireAuth
  },
  {
    path: "/login",
    name: "Login",
    component: LoginBox
  },
  // {
  //   path: "/loginTOTP",
  //   name: "LoginTOTP",
  //   component: LoginBoxTOTP
  // },
  {
    path: "/logout",
    name: "Logout",
    beforeEnter(to, from, next) {
      auth.logout();
      return next("/login");
    },
  },
  {
    path: "/502",
    name: "502",
    component: Error502,
  },
  {
    path: '/:pathMatch(.*)',
    component: Error404,
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach(async (to, from, next) => {
  // const locale = matchLocale(navigator.languages);
  const paramsLocale = to.query.lang === undefined
    ? matchLocale(navigator.languages)
    : to.query.lang;

  // use locale if paramsLocale is not in SUPPORT_LOCALES
  if (!SUPPORT_LOCALES.includes(paramsLocale)) {
    to.query.lang = i18n.global.locale.value;
    return next(to);
  }

  // load locale messages
  if (!i18n.global.availableLocales.includes(paramsLocale)) {
    await loadLocaleMessages(i18n, paramsLocale)
  }

  // set i18n language
  setI18nLanguage(i18n, paramsLocale)

  return next()
});

export default router;