<script>
/**
 * Mixin providing a form validation.
 * In case form validation fails, the class c--invalid is set
 * on the <form> tag and can be used to control further error
 * display using CSS style.
 * When failing, the data property formValidationFailed will be
 * set to true as well and thus can be used to control any
 * VUE logic in the mixed-in component.
 */

export default {
  name: "C4Validation",
  data() {
    return {
      formValidationFailed: false,
    };
  },
  methods: {
    validate(form) {
      const valid = form?.checkValidity();
      const fields = form?.querySelectorAll('.invalid, [invalid="true"]');

      if (!valid || fields?.length > 0) {
        form.classList.add("c4-invalid");
        this.formValidationFailed = true;
      } else {
        form.classList.remove("c4-invalid");
        this.formValidationFailed = false;
      }

      return valid && fields?.length === 0;
    },
  },
};
</script>
