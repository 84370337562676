<template>
  <div class="c4-wizard">
    <div class="c4-wizard-progress-wrap w3-margin-top">
      <div
        class="c4-wizard-progress-bar"
        :style="{ width: progressWidth + '%' }"
      ></div>
      <div class="c4-wizard-progress">
        <div
          class="c4-wizard-progress-tab"
          v-for="(step, index) in progress"
          :key="index"
        >
          <div
            class="c4-wizard-progress-icon"
            :class="{ active: step.name === currentPage.name }"
            v-html="step.progress.icon"
          ></div>
          <!-- Possible bug: need $t at least once to initialize NLS correctly -->
          <!-- <div class="w3-text-blue w3-hide-small" v-t="step.label" /> -->
          <div class="c4-wizard-progress-label w3-hide-small">
            {{ $t(step.label) }}
          </div>
        </div>
      </div>
    </div>

    <div class="c4-wizard-container">
      <keep-alive>
        <component
          :is="currentPageComponent"
          :props="currentPage.props"
          :referrer="currentPage.referrer"
          @page-prev="prev"
          @page-next="next"
          @submit="submit"
        ></component>
      </keep-alive>
    </div>
    <BusySpinner :show="svcBusy" />
  </div>
</template>

<style>
.c4-wizard-progress-wrap {
  position: relative;
  max-width: 1024px;
  width: stretch;
}
.c4-wizard-progress {
  position: relative;
  display: flex;
  flex-direction: row;
}
.c4-wizard-progress-bar {
  position: absolute;
  top: 1.5rem;
  height: 4px;
  background-color: var(--theme-wizard-progress-text);
  transition: width 0.3s ease;
}
.c4-wizard-progress-tab {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.c4-wizard-progress-icon {
  border: 3px solid var(--theme-wizard-progress-text);
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  color: var(--theme-wizard-progress-text);
  background-color: white;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 130%;
  transition: background-color 0.5s 0.3s, color 0.5s 0.3s ease;
}
.c4-wizard-progress-icon.active {
  background-color: var(--theme-wizard-progress-text);
  color: var(--theme-wizard-progress);
}
.c4-wizard-progress-label {
  color: var(--theme-wizard-progress-text);
}
</style>

<script>
import C4GeneralPage from "@/components/pages/C4General.vue";
import C4UserInfoPage from "@/components/pages/C4UserInfo.vue";
import C4CaseInfoPage from "@/components/pages/C4CaseInfo.vue";
import C4SubmitPage from "@/components/pages/C4Submit.vue";
import C4NoRobotsPage from "@/components/pages/C4NoRobots.vue";
import C4FeedbackPage from "@/components/pages/C4Feedback.vue";
import C4SubmitErrorPage from "@/components/pages/C4SubmitError.vue";
import BusySpinner from "@/components/BusySpinner.vue";
import axios from "axios";

var wizardStep;

export default {
  name: "C4Wizard",
  components: {
    C4GeneralPage,
    C4UserInfoPage,
    C4CaseInfoPage,
    C4SubmitPage,
    C4NoRobotsPage,
    C4FeedbackPage,
    C4SubmitErrorPage,
    BusySpinner,
  },
  data() {
    var thePages = [
      {
        name: "general",
        label: "general.l.step",
        component: "C4GeneralPage",
        props: null,
        progress: { include: true, icon: '<i class="far fa-handshake"></i>' },
      },
      {
        name: "userinfo",
        label: "userinfo.l.step",
        component: "C4UserInfoPage",
        props: null,
        progress: { include: true, icon: '<i class="far fa-user"></i>' },
      },
      {
        name: "caseinfo",
        label: "caseinfo.l.step",
        component: "C4CaseInfoPage",
        props: null,
        progress: { include: true, icon: '<i class="far fa-file-alt"></i>' },
      },
      {
        name: "submit",
        label: "submit.l.step",
        component: "C4SubmitPage",
        props: null,
        progress: { include: true, icon: '<i class="fas fa-check"></i>' },
      },
      {
        name: "robots",
        component: "C4NoRobotsPage",
        props: null,
        progress: { include: false },
      },
      {
        name: "feedback",
        label: "feedback.l.step",
        component: "C4FeedbackPage",
        props: null,
        progress: { include: true, icon: '<i class="fas fa-hashtag"></i>' },
      },
      {
        name: "error",
        component: "C4SubmitErrorPage",
        props: null,
        progress: { include: false },
      },
    ];
    return {
      pages: thePages,
      currentPage: thePages[0],
      formData: {},
      summary: {},
      svcBusy: false,
    };
  },
  methods: {
    /**
     * Handle page next event.
     * Switch active component, save form data of current component.
     */
    next(e) {
      const prevPage = this.currentPage.name;
      const props = this.currentPage.props;
      // save form data of current page
      this.formData[prevPage] = e.data;
      this.summary[this.currentPage.label] = e.summary;
      // set new active page
      this.currentPage = this.pages.find((p) => p.name === e.page);
      // set page properties
      // this.currentPage.props = e.props ? e.props : {};
      this.currentPage.props = { ...props, ...e.props };
      this.currentPage.referrer = prevPage;
      this.currentPage.props.summary = this.summary;
    },
    prev(page) {
      this.currentPage = this.pages.find((p) => p.name === page);
    },
    /**
     * Collect all form data and submit to server
     */
    submit(page) {
      // merge all form data
      var data = new FormData();
      for (var f in this.formData) {
        for (var d of this.formData[f]) {
          if (d[0] === "email") {
            if (d[1].trim() !== "") data.set("email", d[1]);
          } else {
            data.append(d[0], d[1]);
          }
        }
      }

      // submit current locale
      data.append("submitLocale", this.$i18n.locale);

      const urlParams = new URLSearchParams(window.location.search);
      let refId = this.currentPage?.props?.company?.referrer;
      // if no referrer was transferred during initialization, take the one from URI
      refId = refId ? refId : urlParams.get("refId");
      var _self = this;
      this.svcBusy = true;
      axios
        .post("/e4/c4/case/create", data, {
          headers: {
            "X-RefToken": refId,
          },
        })
        .then(() => {
          _self.svcBusy = false;
          _self.next({
            page: page,
            data: null,
          });
        })
        .catch(() => {
          // change icon of feedback page in wizard
          let fbPage = this.pages.find((p) => p.name === "feedback");
          fbPage.progress.icon = '<i class="fas fa-exclamation"></i>';
          _self.svcBusy = false;
          _self.next({
            page: "error",
            data: null,
          });
        });
    },
  },
  computed: {
    currentPageComponent() {
      return this.currentPage.component;
    },
    progressWidth() {
      let progressSteps = this.progress;

      let count = progressSteps.length;

      let width = 100 / count;

      let start = width / 2;

      wizardStep = this.currentPage.progress.include
        ? progressSteps.indexOf(this.currentPage)
        : wizardStep;

      return start + wizardStep * width;
    },
    progress() {
      return this.pages.filter((page) => page.progress.include);
    },
  },
};
</script>
