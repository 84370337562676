<template>
  <div class="c4-portal-background">
    <!-- :sytle="{ backgroundImage: `url(${require('@/assets/trust.jpg')})` }" -->
    <div class="c4-portal-overlay">
      <div
        class="w3-display-container w3-auto"
        style="background-color: #ffffff66"
      >
        <div class="w3-card-4">
          <div class="w3-container">
            <h1 class="c4-text-header w3-padding w3-padding-top-24">
              <!-- Possible bug: need $t at least once to initialize NLS correctly -->
              <!-- <span v-t="'portal.l.view'" /> -->
              <span>{{ $t("portal.l.view") }}</span>
            </h1>
          </div>

          <div class="w3-container w3-hide-medium w3-hide-large w3-center">
            <div class="w3-row">
              <div class="w3-col s6">
                <a
                  href="#search"
                  v-t="'portal.l.search'"
                />
              </div>
              <div class="w3-col s6">
                <a
                  href="#login"
                  v-t="'common.btn.login'"
                />
              </div>
            </div>
          </div>

          <div class="w3-padding">
            <div class="w3-row">
              <div class="w3-col l8">
                <div
                  class="w3-container w3-padding-24 w3-justify c4-large"
                  v-t="'portal.m.intro'"
                />
              </div>
              <div class="w3-col l4 w3-hide-medium w3-hide-small">
                <div class="w3-card-4 w3-margin">
                  <img
                    src="../assets/security.jpg"
                    class="w3-image w3-round-small"
                  />
                </div>
              </div>
            </div>
            <div class="w3-row">
              <div
                id="search"
                class="w3-col m6 w3-padding w3-hover-shadow"
              >
                <h4
                  class="w3-center"
                  v-t="'portal.l.search'"
                />
                <div class="w3-container w3-justify w3-padding w3-mobile">
                  <span v-t="'portal.m.search'" />
                </div>
                <div class="w3-margin">
                  <form @submit.prevent.stop>
                    <div class="c4-search">
                      <input
                        class="w3-input w3-border"
                        type="text"
                        required
                        v-model.trim="queryString"
                        @keydown.enter="generateResults"
                      />
                      <button
                        type="button"
                        class="w3-btn w3-ripple"
                        @click="generateResults"
                      >
                        <i class="fa fa-search"></i>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div
                id="login"
                class="w3-col m6 w3-padding w3-hover-shadow"
              >
                <h4
                  class="w3-center"
                  v-t="'portal.l.login'"
                />
                <div
                  class="w3-container w3-padding w3-justify"
                  v-t="'portal.m.login'"
                />
                <div class="w3-center">
                  <router-link
                    :to="{
                      path: '/inbox',
                      query: {
                        lang: $i18n.locale,
                      },
                    }"
                    target="_blank"
                    rel="noopener"
                    v-t="'common.btn.login'"
                    class="w3-btn w3-white w3-border w3-border-blue w3-ripple w3-margin"
                    role="button"
                  />
                </div>
              </div>
            </div>

            <div class="w3-row">
              <div class="w3-col s12 w3-container w3-padding">
                <div v-if="companies.length > 0">
                  <ul class="w3-ul w3-card w3-white">
                    <li
                      class="w3-bar"
                      v-for="(c, index) in companies"
                      :key="index"
                    >
                      <span class="w3-bar-item w3-button w3-xlarge w3-right">
                        <router-link
                          :to="{
                            path: '/case',
                            query: {
                              refId: c.referrer,
                              lang: $i18n.locale,
                            },
                          }"
                          target="_blank"
                          rel="noopener"
                          :title="$t('portal.t.openLink', [c.name])"
                        >
                          <i class="fas fa-external-link-alt w3-text-blue"></i>
                        </router-link>
                      </span>
                      <img
                        :src="c.company.logo"
                        class="w3-bar-item w3-card"
                        style="width: 85px; padding: 0"
                      />
                      <div class="w3-bar-item">
                        <span class="w3-large">{{ c.name }}</span
                        ><br />
                        <span>{{ address(c) }}</span>
                      </div>
                    </li>
                  </ul>
                </div>
                <div
                  v-else
                  class="w3-padding w3-padding-top-24"
                >
                  {{ message }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  components: {},
  name: "C4Portal",
  data() {
    return {
      companies: [],
      queryString: "",
      message: "",
      searchRunning: false,
    };
  },
  methods: {
    generateResults() {
      const _self = this;
      this.companies = [];
      if (!this.searchRunning) {
        this.searchRunning = true;
        axios
          .post("/e4/c4/portal/search", { query: this.queryString })
          .then((response) => {
            response.data.forEach((el) => {
              _self.companies.push(el);
            });

            _self.message =
              _self.companies.length > 0 ? "" : this.$t("portal.m.noentry");
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => (_self.searchRunning = false));
      }
    },
    address(tenant) {
      let str = tenant.company.street;
      let city =
        (tenant.company.zip ? tenant.company.zip + "  " : "") +
        (tenant.company.city ? tenant.company.city : "");
      if (str && str.length > 0)
        return city.length > 0 ? str + " - " + city : str;
      else return city;
    },
  },
};
</script>

<style lang="scss">
.c4-portal-background {
  background-image: url("~@/assets/trust.jpg");
  background-size: cover;
  background-position: center;
}
.c4-portal-overlay {
  background-color: #00000066;
  width: 100%;
  height: 100%;
  overflow: auto;
}
.c4-large {
  font-size: 1em;
  @media (min-width: 768px) {
    font-size: large;
  }
}
.c4-text-header {
  font-size: 1.5em;
  text-align: center;
  @media (min-width: 768px) {
    font-size: 1.75em;
    text-align: left;
  }
}
.c4-search {
  display: flex;
  & input {
    flex: 1;
  }
}
</style>
