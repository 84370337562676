import { nextTick } from 'vue'
import { createI18n } from 'vue-i18n'
// import en from '@/locales/en.js'
import de from '@/locales/de.js'

export const SUPPORT_LOCALES = ['bg', 'de', 'en', 'es', 'fr', 'it', 'sl']

export function setupI18n(options = { locale: 'de' }) {
	const i18n = createI18n(options)
	setI18nLanguage(i18n, options.locale)
	return i18n
}

/**
 * Find the best matching supported catalog for a given set of locales.
 * The locales can be acquired from the browser using navigator.languages
 * @param {Array} locales 
 */
export function matchLocale(locales) {
	let locale = "en";
	// match best locale from supported
	locales.some(l => {
		let supported;
		do {
			supported = SUPPORT_LOCALES.includes(l);
			// success
			if (supported) {
				locale = l;
				return true;
			}

			// not found, try to remove locale components
			let idx = l.lastIndexOf("-");
			// shortening not possible, try next
			if (idx === -1) break;

			// check redued locale
			l = l.substr(0, idx);
		} while (!supported);
	});

	return locale;
}

export function setI18nLanguage(i18n, locale) {
	if (i18n.mode === 'legacy') {
		i18n.global.locale = locale
	} else {
		i18n.global.locale.value = locale
	}
	/**
	 * NOTE:
	 * If you need to specify the language setting for headers, such as the `fetch` API, set it here.
	 * The following is an example for axios.
	 *
	 * axios.defaults.headers.common['Accept-Language'] = locale
	 */
	document.querySelector('html').setAttribute('lang', locale)
}

export async function loadLocaleMessages(i18n, locale) {
	// load locale messages with dynamic import
	const messages = await import(
    /* webpackChunkName: "locale-[request]" */ `./locales/${locale}.js`
	)

	// set locale and locale message
	i18n.global.setLocaleMessage(locale, messages.default)

	return nextTick()
}

export const i18n = setupI18n({
	globalInjection: true,
	legacy: false,
	locale: 'de',
	fallbackLocale: 'en',
	messages: {
		// en,
		de
	}
})
