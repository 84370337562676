<template>
  <div class="c4-wizard-page w3-card-4">
    <div class="w3-container c4-page-header">
      <h2><slot name="header">Page Header</slot></h2>
    </div>
    <div class="c4-page-body">
      <slot name="body"></slot>
    </div>
    <div class="c4-page-nav">
      <slot name="nav"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "C4Page",
};
</script>

<style>
.c4-wizard-page {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin-bottom: 1rem;
}
.c4-page-body {
  height: 100%;
  overflow: auto;
}
.c4-page-nav:not(:empty) {
  border-top: 1px solid lightgrey;
}
</style>