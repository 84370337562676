<template>
  <app-layout />
</template>

<script>
import AppLayout from "@/components/AppLayout.vue";

export default {
  name: "App",
  components: {
    AppLayout,
  },
  created() {
    const urlParams = new URLSearchParams(window.location.search);
    this.refId = urlParams.get("refId");

    // laad custom style if present
    if (this.refId) {
      let file = document.createElement("link");
      file.rel = "stylesheet";
      file.href = `./assets/css/${this.refId}.css`;
      document.head.appendChild(file);
    }
  },
};
</script>

<style lang="scss">
[v-cloak] > * {
  display: none;
}
.c4-app {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}
.c4-header,
.c4-footer {
  display: flex;
  flex: 0 0 auto;
  background-color: #003344;
}
.c4-app-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  height: 100%;
}
.c4-wizard-container {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  flex: 1 0 auto;
  height: 10%;
  width: 100%;
  @media screen and (min-width: 601px) {
    width: 95%;
  }
  @media screen and (min-width: 769px) {
    width: 80%;
    margin-top: 3rem;
  }
  @media screen and (min-width: 1025px) {
    width: 800px;
    margin-top: 3rem;
  }
}
.c4-btn-flat {
  border: none;
  background-color: transparent;
  cursor: pointer;
  outline: none;
}
form.c4-invalid :invalid {
  border-color: red !important;
}
.c4-form-invalid {
  display: none;
  color: red;
}
form.c4-invalid .c4-form-invalid {
  display: block;
}
.c4-chrome {
  background-color: #003344;
}
.c4-text-chrome {
  color: lightsteelblue;
}
.c4-logo {
  font-size: 2rem;
  flex: 1 0 auto;
  margin-left: 1rem;
}
.c4-registered {
  vertical-align: super;
  font-size: 60%;
}
</style>
