/**
 * Authentication using X-Ref token for authentication
 */
import axios from "axios";

let theToken = null;

/**
 * Provides functions that handle app authentication with a e4 server
 */
class Auth {
  /**
   * Initialize the instance with a referrer token
   * required to perform authenticated requests.
   *
   * @param {String} token The referrer token
   */
  setToken(token) {
    theToken = token;
  }

  /**
   * Handles a generic authenticated axios request.
   * I.e., executes Axios get or post call and verifies whether
   * the JWT authentication token has to be silently refreshed.
   *
   * @param {String} method Request method, get | set
   * @param {String} uri The URI to be used in request
   * @param {Object} data Data to be passed into the request. null if ommitted.
   * @returns Promise of the Axios request
   */
  request(method, uri, data = null, buffer = false) {
    // use config to parameterize requests
    let config = {
      method: method.toLowerCase(),
      url: new URL(uri, location.origin),
      // set referrer token token
      headers: {
        "X-RefToken": theToken,
      },

      data: data,
      responseType: buffer ? "blob" : null,
    };

    // wrap axios and return the axios promise / error to caller
    return axios(config);
  }

  /**
   * Perform a GET request
   *
   * @param {String} uri
   * @returns Axios Promise for the request
   */
  get(uri, buffer = false) {
    return this.request("get", uri, null, buffer);
  }

  /**
   * Perform a POST request
   *
   * @param {String} uri Request URI
   * @param {Object} data Request data
   * @returns Axios Promise for the request
   */
  post(uri, data) {
    return this.request("post", uri, data);
  }
}

export default new Auth();
