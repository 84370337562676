<template>
  <div>
    <canvas
      ref="canvas"
      :width="width"
      :height="height"
      class="sy-captcha-canvas"
    >
    </canvas>
    <div>
      <input
        class="w3-center"
        type="text"
        v-model="enteredCode"
        name="captcha-code"
        autocomplete="off"
        ref="input"
      />
      <button
        class="c4-btn-flat"
        type="button"
        @click="generateCode"
        :title="$t('robots.t.generate')"
      >
        <i class="fas fa-sync-alt"></i>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Captcha",
  data() {
    return {
      codes: [],
      images: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
      enteredCode: null,
      width: null,
      height: null,
    };
  },
  methods: {
    dimensions(aspect) {
      // return dimensions adapted to screen sizes
      if (screen.width < 400)
        return { width: 250, height: 250 * aspect, fontSize: 50 };
      if (screen.width < 1981)
        return { width: 320, height: 320 * aspect, fontSize: 60 };
      return { width: 500, height: 500 * aspect, fontSize: 90 };
    },
    generateCode() {
      const _self = this;
      let canvas = this.$refs.canvas;

      // draw background dependent on screen resolution
      let bg = new Image();
      bg.src = require("@/assets/digits.jpg");
      bg.onload = function () {
        // based on sceen width get dimensions of the canvas
        const dim = _self.dimensions(bg.height / bg.width);
        canvas.width = dim.width;
        canvas.height = dim.height;

        let ctx = canvas.getContext("2d");
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.font = `bold ${dim.fontSize}px Arial`;
        ctx.fillStyle = "lightsteelblue";
        ctx.strokeStyle = "grey";
        ctx.textAlign = "center";

        ctx.drawImage(
          bg,
          0,
          0,
          bg.width,
          bg.height,
          0,
          0,
          canvas.width,
          canvas.height
        );

        _self.codes = new Array();
        for (let index = 0; index < 5; index++) {
          const digit = Math.floor(Math.random() * 10);
          _self.codes.push(digit);

          // start and spacing
          const x = dim.fontSize * 0.9 + index * dim.fontSize * 0.9;
          const bias = Math.random() < 0.5 ? -1 : 1;

          ctx.save();
          ctx.rotate(10 * bias * (Math.PI / 180));
          ctx.fillText(digit, x, canvas.height / 2 + 10);
          ctx.strokeText(digit, x, canvas.height / 2 + 10);
          ctx.restore();
        }
      };

      // finally clear input and set focus
      this.enteredCode = null;
      this.$refs.input.focus();
    },
    validateCode() {
      const codeStr = this.codes.join("");
      if (codeStr == this.enteredCode) {
        return true;
      } else {
        this.enteredCode = null;
        this.generateCode();
        return false;
      }
    },
  },
  activated() {
    this.generateCode();
  },
};
</script>

<style>
.sy-captcha-canvas {
  border: 1px solid #d3d3d3;
}
</style>