<template>
  <div>
    <h1 v-t="'error.h.error404'" />
    <p v-t="'error.m.error404'" />
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Error404",
  created() {
    // send to server error location to be able to return a real 404 code.
    // otherwise we get a 404 page, but a 200 return code. nikto doesn't like that 
      window.location.href = location.origin + "/notfound";
  }
};
</script>

<style>
</style>