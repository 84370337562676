<script setup>
/**
 * Audio recorder and player input element
 */
import RecordRTC from "recordrtc";
import { computed, defineEmits, defineExpose, defineProps, ref } from "vue";

const emit = defineEmits(["update:modelValue"]);
defineProps({
  modelValue: Blob,
});
defineExpose({
  acquireRecording,
});

const player = ref(null);

const isStopped = ref(false);
const isRecording = ref(false);
const recordingTime = ref(0);
const recordingInt = ref(null);

// MediaRecorder object
const mediaRecorder = ref(null);
const audioBlob = ref(null);
const audioStream = ref(null);

/**
 * Display time of recording
 */
const minutes = computed(() =>
  Math.floor(recordingTime.value / 60)
    .toString()
    .padStart(2, "0")
);
const seconds = computed(() =>
  (recordingTime.value % 60).toString().padStart(2, "0")
);

async function startRecording() {
  // Cannot (re-)start a stopped recording
  if (mediaRecorder.value && isStopped.value) return;

  if (mediaRecorder.value) {
    if (isRecording.value) {
      // PAUSE
      isRecording.value = false;
      clearInterval(recordingInt.value);
      mediaRecorder.value.pauseRecording();
    } else {
      // RESUME
      isRecording.value = true;
      recordingInt.value = setInterval(() => recordingTime.value++, 1000);
      mediaRecorder.value.resumeRecording();
    }
  } else {
    // setup recorder and start recording
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: false })
      .then((stream) => {
        // stream.value = s;
        mediaRecorder.value = RecordRTC(stream, {
          recorderType: RecordRTC.StereoAudioRecorder,
          type: "audio",
          mimeType: "audio/wav",
          // audiobits seem to have no effect for wav recordings
        });

        mediaRecorder.value.startRecording();
        audioStream.value = stream;
        audioBlob.value = null;
        isRecording.value = true;
        isStopped.value = false;
        recordingInt.value = setInterval(() => recordingTime.value++, 1000);
      })
      .catch((error) => {
        console.error("Error accessing microphone:", error);
      });
  }
}

function stopRecording() {
  mediaRecorder.value?.stopRecording(() => {
    audioBlob.value = mediaRecorder.value.getBlob();

    player.value.src = mediaRecorder.value.toURL();

    // clean up, stop microphone access
    audioStream.value?.getTracks().forEach((track) => track.stop());

    emit("update:modelValue", audioBlob.value);
  });

  clearInterval(recordingInt.value);
  isRecording.value = false;
  isStopped.value = true;
}

function discardRecording() {
  mediaRecorder.value.destroy();
  mediaRecorder.value = null;
  isRecording.value = false;
  isStopped.value = false;
  clearInterval(recordingInt.value);
  recordingTime.value = 0;
  URL.revokeObjectURL(player.value.src);
  player.value.src = null;
  audioBlob.value = null;
  emit("update:modelValue", null);
}

/**
 * Allow other component to stop recording and update the model value.
 * Used to force stopping and getting the audio recording.
 */
function acquireRecording() {
  mediaRecorder.value?.stop();
  clearInterval(recordingInt.value);
  isRecording.value = false;
  isStopped.value = true;

  return new Promise((resolve, reject) => {
    setTimeout(() => {
      if (audioBlob.value?.size > 0) {
        resolve();
      } else {
        reject();
      }
    }, 1000);
  });
}
</script>

<template>
  <div class="sy-media-recorder">
    <div class="w3-center">
      <span v-if="isStopped">
        {{ $t("types.audio.m.stopped") }}
      </span>
      <span v-else-if="!isRecording && !mediaRecorder">
        {{ $t("types.audio.m.start") }}
      </span>
      <span v-else-if="isRecording">
        {{ $t("types.audio.m.recording") }}
      </span>
      <span v-else>
        {{ $t("types.audio.m.paused") }}
      </span>
    </div>
    <div class="sy-recorder-controls">
      <div
        class="sy-btn-default"
        role="button"
        :title="$t('types.audio.t.stop')"
        @click="stopRecording"
      >
        <i class="fas fa-stop"></i>
      </div>

      <div
        class="sy-btn-recording"
        :class="{ recording: isRecording }"
        role="button"
        :title="
          $t(
            `types.audio.t.${
              isRecording
                ? 'pause'
                : !isRecording && !mediaRecorder
                ? 'record'
                : 'unpause'
            }`
          )
        "
        @click="startRecording"
      >
        <i
          v-if="isStopped"
          class="fas fa-microphone-alt-slash"
        />
        <i
          v-else-if="!isRecording"
          class="fas fa-microphone-alt"
        />
        <i
          v-else
          class="fas fa-pause"
        />
      </div>

      <div
        class="sy-btn-default"
        role="button"
        :title="$t('types.audio.t.discard')"
        @click="discardRecording"
      >
        <i class="fas fa-trash-alt"></i>
      </div>
    </div>

    {{ minutes }}:{{ seconds }}

    <audio
      class="sy-audio-player"
      controls
      preload="metadata"
      ref="player"
    />
  </div>
</template>

<style lang="scss">
.sy-media-recorder {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.sy-btn-recording,
.sy-btn-default {
  margin: 0.25rem;
  border-radius: 50%;
  text-align: center;
  box-shadow: 0 2px 5px 2px rgba(158, 158, 158, 0.5);
  cursor: pointer;
}
.sy-btn-recording {
  width: 45px;
  height: 45px;
  line-height: 45px;
  font-size: 150%;
  border: 1px solid red;
  color: white;
  background-color: red;
  &.recording {
    font-size: 125%;
    animation: pulse 1s infinite;
  }
}
.sy-btn-default {
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 115%;
  border: 1px solid black;
  color: black;
}
.sy-recorder-controls {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.sy-audio-player {
  height: 1.75em;
}
// animations
@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
  }
}
</style>
