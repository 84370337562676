import axios from "axios";

let theToken = null;
let SESSION_TIMEOUT = 60 * 5;

const KEY_LIFETIME = "sessionLifetime";
let SESSION_PREFIX = null;

/**
 * Provides functions that handle app authentication with a e4 server
 */
class Auth {
  constructor() {
    window.onstorage = (event) => {
      if (event.key != KEY_LIFETIME) return;
      if (event.newValue === null) {
        this.logout();
      } else {
        this.resetSessionTimer(false);
      }
      // console.log(event.key + ':' + event.newValue + " at " + event.url);
      // console.log("SAVED", window.localStorage.getItem(KEY_LIFETIME));
    };
  }

  /**
   * Handles login to an e4 server using given username and password.
   * Optionally calls the callback after completion of the login
   *
   * @param {String} user
   * @param {String} pass
   */
  async login(user, pass) {
    const uri = "/e4/login";

    try {
      const response = await axios.post(uri, {
        username: user,
        password: pass,
      });

      theToken = response.data.token;
      SESSION_TIMEOUT = response.data.sessionTimeout;
      SESSION_PREFIX = response.data.sessionPrefix;

      this.resetSessionTimer();

      this.startSilentRefreshTimer(response.data.tokenTimeout);

      // notify subscriber
      this.onChange(true);

      return Promise.resolve(SESSION_TIMEOUT);
    } catch (error) {
      this.onChange(false);
      this.onSessionLifetime(null);
      this.onSessionTimeout();
      return Promise.reject(error);
    }
  }

  /**
   * Handles logout from an e4 server.
   */
  logout() {
    const uri = location.origin + "/e4/logoff";

    // removes refresh token
    axios.get(uri);

    theToken = null;
    window.localStorage.removeItem(SESSION_PREFIX + "_" + KEY_LIFETIME);
    this.onChange(false);
    this.onSessionLifetime(null);
    this.onSessionTimeout();
    if (this.sessionTimer) clearTimeout(this.sessionTimer);
    if (this.refreshTimer) clearTimeout(this.refreshTimer);
  }

  /**
   * Fetching a new JWT token using the refresh token in cookie.
   * If called by a silet refresh, the session timeout is not reset.
   *
   * @param {Boolean} silent Whether a silent refresh is called.
   * @returns Promise of the refresh token Axios call
   */
  refresh(silent = false) {
    const uri = "/e4/refresh_token";

    return axios
      .get(uri)
      .then((response) => {
        // update the JWT token
        theToken = response.data.token;
        SESSION_TIMEOUT = response.data.sessionTimeout;
        SESSION_PREFIX = response.data.sessionPrefix;

        // notify subscriber
        this.onChange(true);

        if (!silent) this.resetSessionTimer();

        this.startSilentRefreshTimer(response.data.tokenTimeout);

        return response;
      })
      .catch((error) => {
        console.error("REFRESH ERROR", error);

        // cleanup
        this.logout();

        throw error;
      });
  }

  /**
   * Handles a generic authenticated axios request.
   * I.e., executes Axios get or post call and verifies whether
   * the JWT authentication token has to be silently refreshed.
   *
   * @param {String} method Request method, get | set
   * @param {String} uri The URI to be used in request
   * @param {Object} data Data to be passed into the request. null if ommitted.
   * @returns Promise of the Axios request
   */
  request(method, uri, data = null, buffer = false) {
    const _self = this;

    // use config to parameterize requests
    let config = {
      method: method.toLowerCase(),
      url: new URL(uri, location.origin),
      // set JWT token
      headers: {
        Authorization: `Bearer ${theToken}`,
      },
      data: data,
      responseType: buffer ? "blob" : null,
    };

    this.resetSessionTimer();

    // return the axios promise / error to caller
    return (
      axios(config)
        .then((response) => {
          return Promise.resolve(response);
        })
        // must handle error/refresh try asynchronously
        .catch(async (error) => {
          // if we get an authentication error, call a silent token refresh
          if (error.response?.status === 403) {
            /*
             * To make this work prpoerly, we have to return the result from
             * the embedded axios call in the promise of a succesful token
             * refresh call, or the token refresh error.
             */
            try {
              await _self.refresh();

              // successful token refresh, try original request again
              // using same config as initially, but setting fresh token
              config.headers = {
                Authorization: `Bearer ${theToken}`,
              };
              return axios(config)
                .then((response2) => {
                  // successful 2nd try, return promise to caller
                  return Promise.resolve(response2);
                })
                .catch((error2) => {
                  // nope, call failed for some other reason
                  // (shouldn't be authentication error, because that
                  // was fixed just above)
                  return Promise.reject(error2);
                });
            } catch (error1) {
              // error while token refresh, pass on to caller
              return Promise.reject(error1);
            }
          } else {
            // any other error that is not 403, pass on to caller
            return Promise.reject(error);
          }
        })
    );
  }

  /**
   * Perform a GET request
   *
   * @param {String} uri
   * @returns Axios Promise for the request
   */
  getRequest(uri, buffer = false) {
    return this.request("get", uri, null, buffer);
  }
  get(uri, buffer = false) {
    return this.request("get", uri, null, buffer);
  }

  /**
   * Perform a POST request
   *
   * @param {String} uri Request URI
   * @param {Object} data Request data
   * @returns Axios Promise for the request
   */
  postRequest(uri, data) {
    return this.request("post", uri, data);
  }

  /**
   * Returns whether we have an authenticated user.
   *
   * @returns true | false
   */
  loggedIn() {
    const timeout = window.localStorage.getItem(
      SESSION_PREFIX + "_" + KEY_LIFETIME
    );
    // console.log("Verifying session timeout: ", new Date(timeout), new Date());
    if (SESSION_PREFIX != null && new Date(timeout) <= new Date()) {
      // timed out session
      console.warn("Session timed out");
      return Promise.resolve(false);
    } else {
      // session still valid, or page reload performed (= SESSION_TIMEOUT null) try a token refresh
      // console.log("Session still valid or page reload");
      if (theToken === null) {
        // need a new token
        return this.refresh()
          .then(() => {
            // console.log("Refresh success");
            return Promise.resolve(true);
          })
          .catch(() => {
            console.error("Refresh failed");
            return Promise.resolve(false);
          });
      } else {
        // everything should be good
        this.onChange(true);
        return Promise.resolve(true);
      }
    }
  }

  async isLoggedIn() {
    const res = await this.loggedIn();
    // console.log("Is logged in", res);

    return res;
  }

  /**
   * Internal use only. Reset session timeout and timer.
   * Propagates the current timeout, i.e. the initial value, to consumer.
   * Sets an auto logout timer that logs out the session when timeout is expired.
   * @param {Boolean} persist Whether to update the lifetime in local storage
   */
  resetSessionTimer(persist = true) {
    this.onSessionLifetime(SESSION_TIMEOUT);

    if (persist)
      window.localStorage.setItem(
        SESSION_PREFIX + "_" + KEY_LIFETIME,
        new Date(new Date().getTime() + SESSION_TIMEOUT * 1000)
      );

    // set an autologout timer
    if (this.sessionTimer) clearTimeout(this.sessionTimer);
    this.sessionTimer = setTimeout(() => this.logout(), SESSION_TIMEOUT * 1000);
  }

  /**
   * Internal use only. Trigger a silent token refresh after the token
   * expiration. For that the refresh token is used.
   *
   * @param {Number} timeout Token expiration in seconds
   */
  startSilentRefreshTimer(timeout) {
    // skip for invalid timeout value
    if (timeout < 10) return;
    // set the token refresh timer
    if (this.refreshTimer) clearTimeout(this.refreshTimer);
    this.refreshTimer = setTimeout(() => {
      console.log("Silently requesting refresh for token");
      this.refresh(true);
    }, timeout * 1000);
  }

  /**
   * Notify consumer about changes in logged in state
   */
  onChange() {}

  /**
   * Notify consumer about the session's lifetime (timeout)
   */
  onSessionLifetime() {}

  /**
   * Notify consumer about a timed out session
   */
  onSessionTimeout() {}
}

export default new Auth();
