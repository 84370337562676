/**
 * Wraps a modelValue into a computed getter/setter to track 
 * modelValue updates.
 */
import { computed } from "vue";

export function useModelWrapper(props, emit) {
	return computed({
		get: () => props.modelValue,
		set: (value) => {
			// emit("model:dirty", true)
			emit("update:modelValue", value)
		}
	})
}

export function useValueWrapper(props, emit) {
	return computed({
		get: () => props.value,
		set: (value) =>
			emit("input", value)
	})
}