<template>
  <c-4-page>
    <template v-slot:header>{{ $t("caseinfo.l.page") }}</template>

    <template v-slot:body>
      <form
        class="w3-container w3-margin-top"
        ref="form"
      >
        <div class="w3-container w3-section">
          <div>
            <label>
              {{ $t("caseinfo.l.area") }}
              <small> {{ $t("common.l.required") }}</small>
            </label>
            <select
              class="w3-select w3-border"
              v-model="area"
              name="area"
              required
            >
              <!-- show Select... entry -->
              <option
                value=""
                selected
                disabled
              >
                {{ $t("common.info.select") }}
              </option>

              <!-- Render options based on catalog strings -->
              <option
                v-for="opt in areaList"
                :key="opt.key"
                :value="opt.value"
                v-t="opt.value"
              ></option>
              <option
                value="other"
                v-t="'common.info.other'"
              ></option>
            </select>
          </div>
          <!-- "Other" selected, provide input field -->
          <div>
            <input
              v-if="area === 'other'"
              class="w3-input w3-border"
              type="text"
              name="areaOther"
              required
              ref="areainput"
            />
          </div>

          <!-- 
          <div
            v-if="area"
            class="w3-col w3-padding-small"
          >
            <div
              class="w3-card c4-hiding-section"
              :class="{ collapsed: !showInfoSection }"
              :style="{
                'max-height': $refs.collapse?.clientHeight + 100 + 'px',
              }"
            >
              <div
                style="white-space: pre-wrap"
                ref="collapse"
              >
                {{ $t("caseinfo.info.area1") }}
              </div>

              <div class="c4-hiding-button">
                <i
                  class="fas fa-caret-down"
                  @click="showInfoSection = !showInfoSection"
                />
              </div>
            </div>
          </div>
           -->
        </div>

        <div class="w3-container w3-section">
          <label>
            {{ $t("caseinfo.l.description") }}
            <small> {{ $t("common.l.required") }}</small>
          </label>

          <!-- hide when reporting ononymous -->
          <div
            v-if="props.company.allowAudio && !props.anonymous"
            class="w3-padding-small"
          >
            <div
              class="w3-btn"
              @click="voiceInput = !voiceInput"
            >
              <span v-if="voiceInput">
                <i class="fas fa-signature w3-margin-right" />
                <span>{{ $t("caseinfo.btn.text") }}</span>
              </span>
              <span v-else>
                <i class="fas fa-microphone-alt w3-margin-right" />
                <span>{{ $t("caseinfo.btn.audio") }}</span>
              </span>
            </div>
          </div>

          <textarea
            class="w3-input w3-border"
            v-if="props.anonymous || !voiceInput"
            name="description"
            v-model="backDescr"
            style="resize: vertical; height: 10rem"
            required
          />
          <AudioInput
            v-else
            class="w3-border w3-padding"
            :class="{ 'w3-border-red': missingAudio }"
            style="height: 10rem"
            v-model="audio"
            ref="audioEl"
          />
        </div>

        <div class="w3-container w3-section">
          <label v-t="'caseinfo.l.when'" />
          <input
            class="w3-input w3-border"
            type="text"
            name="when"
          />
        </div>

        <div class="w3-container w3-section">
          <label v-t="'caseinfo.l.where'" />
          <input
            class="w3-input w3-border"
            type="text"
            name="where"
          />
        </div>

        <div class="w3-container w3-section">
          <div v-t="'caseinfo.l.ongoing'" />
          <div>
            <input
              class="w3-radio w3-margin-left"
              type="radio"
              name="ongoing"
              value="common.l.yes"
            />
            <label
              class="w3-padding-small"
              v-t="'common.l.yes'"
            />

            <input
              class="w3-radio w3-margin-left"
              type="radio"
              name="ongoing"
              value="common.l.no"
              checked
            />
            <label
              class="w3-padding-small"
              v-t="'common.l.no'"
            />

            <input
              class="w3-radio w3-margin-left"
              type="radio"
              name="ongoing"
              value="common.l.unknown"
              checked
            />
            <label
              class="w3-padding-small"
              v-t="'common.l.unknown'"
            />
          </div>
        </div>

        <div class="w3-container w3-section">
          <div v-t="'caseinfo.l.awareness'" />
          <div>
            <input
              class="w3-radio w3-margin-left"
              type="radio"
              name="awareness"
              value="common.l.yes"
            />
            <label
              class="w3-padding-small"
              v-t="'common.l.yes'"
            />

            <input
              class="w3-radio w3-margin-left"
              type="radio"
              name="awareness"
              value="common.l.no"
              checked
            />
            <label
              class="w3-padding-small"
              v-t="'common.l.no'"
            />
          </div>
        </div>

        <div class="w3-container w3-section">
          <div v-t="'caseinfo.l.involvedSelf'" />
          <div>
            <input
              class="w3-radio w3-margin-left"
              type="radio"
              name="involvedSelf"
              value="common.l.yes"
            />
            <label
              class="w3-padding-small"
              v-t="'common.l.yes'"
            />

            <input
              class="w3-radio w3-margin-left"
              type="radio"
              name="involvedSelf"
              value="common.l.no"
              checked
            />
            <label
              class="w3-padding-small"
              v-t="'common.l.no'"
            />
          </div>
        </div>

        <div class="w3-container w3-section">
          <label v-t="'caseinfo.l.involvedPeople'" />
          <textarea
            class="w3-input w3-border"
            name="involvedPeople"
            rows="3"
            style="resize: vertical"
          />
        </div>

        <div class="w3-container w3-section">
          <div v-t="'caseinfo.l.mgmtInformed'" />
          <div>
            <input
              class="w3-radio w3-margin-left"
              type="radio"
              name="mgmtInformed"
              value="common.l.yes"
            />
            <label
              class="w3-padding-small"
              v-t="'common.l.yes'"
            />

            <input
              class="w3-radio w3-margin-left"
              type="radio"
              name="mgmtInformed"
              value="common.l.no"
            />
            <label
              class="w3-padding-small"
              v-t="'common.l.no'"
            />

            <input
              class="w3-radio w3-margin-left"
              type="radio"
              name="mgmtInformed"
              value="common.l.unknown"
              checked
            />
            <label
              class="w3-padding-small"
              v-t="'common.l.unknown'"
            />
          </div>
        </div>

        <div class="w3-container w3-section">
          <label>{{ $t("caseinfo.l.upload", [100]) }}</label>
          <div class="sy-info-text">{{ $t("caseinfo.l.uploadWarn") }}</div>

          <UploadInput
            name="file[]"
            :multiple="true"
            :max-size="100"
          />
        </div>
      </form>
    </template>
    <template v-slot:nav>
      <div
        v-show="formValidationFailed"
        class="w3-margin-left w3-text-red"
      >
        <small v-t="'common.m.required'" />
      </div>

      <button
        class="w3-btn w3-white w3-border w3-border-blue w3-ripple w3-margin"
        type="button"
        @click="$emit('page-prev', referrer)"
        v-t="'common.btn.back'"
      ></button>
      <button
        class="w3-btn w3-white w3-border w3-border-blue w3-ripple w3-margin"
        type="button"
        @click="next('submit')"
        v-t="'common.btn.next'"
      ></button>
    </template>
  </c-4-page>
</template>

<script>
import C4Page from "@/components/C4Page.vue";
import C4Summary from "@/components/C4Summary.vue";
import C4Validation from "@/components/C4Validation.vue";
import AudioInput from "../AudioInput.vue";
import UploadInput from "../UploadInput.vue";

export default {
  components: { C4Page, AudioInput, UploadInput },
  name: "C4CaseInfoPage",
  mixins: [C4Validation, C4Summary],
  emits: ["page-next", "page-prev"],
  props: {
    props: Object,
    referrer: String,
  },
  data() {
    return {
      area: "",
      audioSubmission: false,
      audio: null,
      voiceInput: false,
      missingAudio: false,
      showInfoSection: false,
      backDescr: null,
    };
  },
  methods: {
    /**
     * Validate form data and proceed to next page
     */
    async next(page) {
      // validate form before we leave page
      if (!this.validate(this.$refs.form)) return false;

      const data = new FormData(this.$refs.form);

      if (this.voiceInput) {
        // voice input is required
        if (!this.audio) {
          // nothing there yet, maybe the user has not stopped the recording
          try {
            // try to acquire audio
            await this.$refs.audioEl?.acquireRecording();
            this.missingAudio = false;
            this.$refs.form.classList.remove("c4-invalid");
            this.formValidationFailed = false;
          } catch {
            this.missingAudio = true;
            this.$refs.form.classList.add("c4-invalid");
            this.formValidationFailed = true;
            return false;
          }
        }

        data.set("audio", this.audio, "audio.wav");
        data.set("description", this.$t("caseinfo.m.audio"));
      }

      this.$emit("page-next", {
        page: page,
        data: data,
        summary: this.summary(data, {
          area: {
            key: "caseinfo.l.area",
            nlsValue: true,
            alternative: "areaOther",
          },
          description: "caseinfo.l.descriptionShort",
          when: "caseinfo.l.when",
          where: "caseinfo.l.where",
          ongoing: {
            key: "caseinfo.l.ongoing",
            nlsValue: true,
          },
          awareness: {
            key: "caseinfo.l.awareness",
            nlsValue: true,
          },
          involvedSelf: {
            key: "caseinfo.l.involvedSelf",
            nlsValue: true,
          },
          involvedPeople: "caseinfo.l.involvedPeople",
          mgmtInformed: {
            key: "caseinfo.l.mgmtInformed",
            nlsValue: true,
          },
          "file[]": "caseinfo.l.files",
        }),
      });
    },
  },
  computed: {
    /**
     * fill area list from catalog or the custom list of areas if present
     */
    areaList() {
      if (this.props.company.areas) {
        return this.props.company.areas;
      } else {
        return [
          { key: "caseinfo.opt.area1", value: this.$t("caseinfo.opt.area1") },
          { key: "caseinfo.opt.area2", value: this.$t("caseinfo.opt.area2") },
          { key: "caseinfo.opt.area3", value: this.$t("caseinfo.opt.area3") },
          { key: "caseinfo.opt.area4", value: this.$t("caseinfo.opt.area4") },
          { key: "caseinfo.opt.area5", value: this.$t("caseinfo.opt.area5") },
          { key: "caseinfo.opt.area6", value: this.$t("caseinfo.opt.area6") },
          { key: "caseinfo.opt.area7", value: this.$t("caseinfo.opt.area7") },
          { key: "caseinfo.opt.area8", value: this.$t("caseinfo.opt.area8") },
          { key: "caseinfo.opt.area9", value: this.$t("caseinfo.opt.area9") },
          { key: "caseinfo.opt.area10", value: this.$t("caseinfo.opt.area10") },
        ];
      }
    },
  },
  watch: {
    area(val) {
      // auto focus when type other is selected from relationship
      if (val === "other") {
        setTimeout(() => {
          this.$refs.areainput.focus();
        }, 250);
      }
    },
  },
};
</script>

<style></style>
