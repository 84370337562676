<script setup>
/**
 * Render an audio message with a player control
 */
import { inject, ref, defineProps } from "vue";

const props = defineProps({
  message: Object,
});

const Auth = inject("Auth");

const player = ref(null);
const audioBlob = ref(null);
const loading = ref(false);

/**
 * acquire audio data from server
 */
function loadAudio() {
  if (!audioBlob.value) {
    // delay load indicator
    let tio = setTimeout(() => {
      loading.value = true;
    }, 500);
    const src = location.origin + props.message?.src;
    // important, get as BLOB
    Auth.get(src, true)
      .then((response) => {
        audioBlob.value = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        player.value.src = URL.createObjectURL(audioBlob.value);
        clearTimeout(tio);
        // delay for nicer disappearance transition
        setTimeout(() => {
          loading.value = false;
        }, 500);
      })
      .catch((error) => console.log(error));
  }
}
</script>

<template>
  <div class="sy-audio-message">
    <i
      v-if="loading"
      class="fas fa-circle-notch fa-spin"
      :class="{ loaded: audioBlob }"
    />
    <i
      v-else
      class="fas fa-cloud-download-alt"
      :class="{ loaded: audioBlob }"
      @click="loadAudio"
    />
    <div class="sy-audio-player">
      <audio
        class="sy-audio-player"
        controls
        preload="metadata"
        ref="player"
      />
      <!-- disabled blend for audio player -->
      <div v-show="!audioBlob" />
    </div>
  </div>
</template>

<style lang="scss">
.sy-audio-message {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;

  > i {
    flex-shrink: 1;
    cursor: pointer;
    margin-right: 0.5rem;
    width: 1rem;
    color: var(--theme-l1);
    transition: opacity 0.5s ease-out, margin 0.5s ease, width 0.5s;

    &.loaded {
      margin-right: 0;
      opacity: 0;
      width: 0;
    }
  }
}
.sy-audio-player {
  position: relative;
  height: 1.75rem;
  > div {
    position: absolute;
    background-color: white;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 1;
    opacity: 0.6;
  }
}
</style>
