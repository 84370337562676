<template>
  <c-4-page>
    <template v-slot:header>{{ $t("general.l.page") }}</template>

    <template v-slot:body>
      <div class="w3-padding">
        <div class="w3-container">
          <div
            class="w3-col w3-justify"
            v-t="'general.m.welcome'"
          />
        </div>
      </div>

      <form
        class="w3-padding"
        ref="form"
      >
        <div class="w3-container w3-section">
          <div v-if="organizations.length === 1">
            <label>
              {{ $t("general.l.company") }}
            </label>
            <input
              class="w3-input w3-border"
              type="text"
              disabled
              :value="organizations[0].name"
            />
          </div>
          <div v-else>
            <label>
              {{ $t("general.l.company") }}
              <small> {{ $t("common.l.required") }}</small>
            </label>
            <select
              class="w3-select w3-border"
              v-model="company"
              name="company"
              required
            >
              <option
                v-if="organizations.length > 1"
                value=""
                disabled
              >
                {{ $t("common.info.select") }}
              </option>
              <option
                v-if="organizations.length === 0"
                value=""
                disabled
              >
                {{ $t("common.info.noEntries") }}
              </option>
              <option
                v-for="(org, index) in organizations"
                :key="index"
                :value="index"
              >
                {{ org.name }}
              </option>
            </select>
          </div>
        </div>

        <div
          v-if="organizations[company]?.allowAnonymous"
          class="w3-container w3-padding-top-24"
        >
          <div class="w3-half">
            <input
              class="w3-radio w3-margin-right"
              type="radio"
              :value="false"
              v-model="anonymous"
            />
            <label> {{ $t("general.l.identified") }}</label>
          </div>
          <div class="w3-half">
            <input
              class="w3-radio w3-margin-right"
              type="radio"
              :value="true"
              v-model="anonymous"
            />
            <label> {{ $t("general.l.anonymous") }}</label>
          </div>
        </div>

        <div
          style="display: flex; align-items: center"
          class="w3-container w3-section"
        >
          <div
            class="w3-text-blue w3-padding"
            @click="showInfo = !showInfo"
          >
            <i class="fas fa-info-circle fa-2x"></i>
          </div>

          <div
            v-if="anonymous"
            style="white-space-collapse: preserve"
            class="w3-padding w3-justify"
          >
            {{ $t("general.m.anonymous") }}
          </div>
          <div
            v-else
            class="w3-padding w3-justify"
            style="white-space-collapse: preserve"
          >
            {{ $t("general.m.identified") }}
          </div>
        </div>

        <div class="w3-container w3-section">
          <div>
            <label>
              {{ $t("general.l.relation") }}
              <small> {{ $t("common.l.required") }}</small>
            </label>

            <select
              class="w3-select w3-border"
              v-model="relation"
              name="relation"
              required
            >
              <option
                value=""
                selected
                disabled
              >
                {{ $t("common.info.select") }}
              </option>
              <option
                value="general.opt.employee"
                v-t="'general.opt.employee'"
              ></option>
              <option
                value="general.opt.customer"
                v-t="'general.opt.customer'"
              ></option>
              <option
                value="general.opt.supplier"
                v-t="'general.opt.supplier'"
              ></option>
              <option
                value="other"
                v-t="'common.info.other'"
              ></option>
            </select>
          </div>

          <div>
            <input
              v-if="relation == 'other'"
              class="w3-input w3-border"
              type="text"
              name="relationOther"
              required
              ref="input"
            />
          </div>
        </div>
      </form>
    </template>
    <template v-slot:nav>
      <div
        v-show="formValidationFailed"
        class="w3-margin-left w3-text-red"
      >
        <small v-t="'common.m.required'" />
      </div>

      <button
        class="w3-btn w3-white w3-border w3-border-blue w3-ripple w3-margin"
        type="button"
        @click="next(anonymous ? 'caseinfo' : 'userinfo')"
        v-t="'common.btn.next'"
      ></button>
    </template>
  </c-4-page>
</template>

<script>
import C4Page from "@/components/C4Page.vue";
import C4Summary from "@/components/C4Summary.vue";
import C4Validation from "@/components/C4Validation.vue";

export default {
  components: { C4Page },
  name: "C4GeneralPage",
  mixins: [C4Validation, C4Summary],
  emits: ["page-next"],
  inject: ["Auth"],
  props: {
    props: Object,
  },
  data() {
    return {
      company: "",
      relation: "",
      organizations: [],
      anonymous: false,
      allowAnonymous: false,
    };
  },
  methods: {
    /**
     * Validate form data and proceed to next page
     */
    next(page) {
      // validate form before we leave page
      if (!this.validate(this.$refs.form)) return false;

      // generate a page abstract
      const data = new FormData(this.$refs.form);
      const index = data.get("company") || 0;
      data.set("company", this.organizations[index].name);

      this.$emit("page-next", {
        page: page,
        data: data,
        summary: this.summary(data, {
          company: "general.l.company",
          relation: {
            key: "general.l.relation",
            nlsValue: true,
            alternative: "relationOther",
          },
        }),
        props: {
          employee: this.relation === "general.opt.employee",
          company: this.organizations[this.company],
          anonymous: this.anonymous,
          token: this.token,
        },
      });
    },
  },
  watch: {
    relation(r) {
      // auto focus when type other is selected from relation
      if (r == "other") {
        setTimeout(() => {
          this.$refs.input.focus();
        }, 250);
      }
    },
    company(c) {
      if (!this.organizations[c]?.allowAnonymous) {
        this.anonymous = false;
      }
      // if (this.organizations[c]?.logo) {
      //   const logo = document.getElementById("logo_image");
      //   //  set company logo
      //   logo.src = this.organizations[c]?.logo;
      // }
    },
  },
  mounted() {
    const _self = this;
    const urlParams = new URLSearchParams(window.location.search);
    let refId = urlParams.get("refId");

    this.Auth.setToken(refId);
    this.Auth.get("/e4/c4/case/initialize")
      .then(function (response) {
        _self.organizations = response.data.organizations;
        _self.token = response.data.token;
        _self.allowAnonymous = response.data.allowAnonymous;

        // setup company select control
        if (_self.organizations.length === 1) {
          // select company if only one is present
          _self.company = 0;
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  },
};
</script>

<style></style>
