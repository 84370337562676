<script>
export default {
  name: "C4Summary",
  methods: {
    summary(data, config) {
      let a = "";

      for (const k in config) {
        const cfg = config[k];
        let label;
        let val;
        if (typeof cfg === "string") {
          // we have raw data as value
          val = data.get(k);

          // check if we got a file data object
          if (val instanceof File) {
            // re-get to get all files
            const files = data.getAll(k);
            let list = [];
            files.forEach(f => list.push(f.name));
            val = list.join(", ");
          }

          label = this.$t(cfg);
        }
        else if (cfg.value) {
          // we have a preset value that we use to display in the summary
          val = cfg.value;
        } 
        else {
          // we have NLS or data from an alternate field as value
          let key = data.get(k);
          // need some special resolving for bi-/tri state radios
          if (key === "true") {
            key = "common.l.yes";
          }
          if (key === "false") {
            key = "common.l.no";
          }
          if (key === "na") {
            key = "common.l.unknown";
          }
          // check if we have data in an alternative field,
          // otherwise we use the key as message key
          val = data.get(cfg.alternative) || (key && this.$t(key));

          label = this.$t(cfg.key);
        }

        // format output
        if (val) {
          a += "<p>";
          if (label) a += `<b>${label}</b><br />`;
          a += `${val}</p>`;
        }
      }

      return a;
    },
  },
};
</script>