<script setup>
/**
 * The whistleblower inbox where messages are displayed,
 * new messages can be submitted and files uploaded.
 */
import auth from "@/auth.js";
import UploadInput from "@/components/UploadInput.vue";
import ChatControl from "@/components/messaging/ChatControl.vue";
import { onMounted, ref, provide } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

// override Xref authentication from AppLayout so that audio can be loaded
provide("Auth", auth);

const router = useRouter();

const i18n = useI18n({});

const chatEl = ref(null);
const uploadEl = ref(null);

const settings = ref(null);
const messages = ref([]);
const textMsg = ref(null);
const refresh = ref(false);

/**
 * Append a message
 */
function submitMessage() {
  let data = new FormData();
  data.set("message", textMsg.value);
  data.set("submitLocale", i18n.locale.value);

  auth
    .postRequest("/e4/c4/case/update", data)
    .then(() => {
      chatEl.value.reset();
      setTimeout(() => {
        refreshMessages();
      }, 2000);
    })
    .catch((error) => {
      if (error.response.status === 502) {
        router.push("502");
      }
    });
}
/**
 * Append an audio recording
 */
function submitAudio(data) {
  data.set("submitLocale", i18n.locale.value);

  auth
    .postRequest("/e4/c4/case/update", data)
    .then(() => {
      chatEl.value.reset();
      setTimeout(() => {
        refreshMessages();
      }, 2000);
    })
    .catch((error) => {
      if (error.response?.status === 502) {
        router.push("502");
      }
    });
}
/**
 * Upload files
 */
function uploadFiles(e) {
  if (uploadEl.value.empty()) return false;

  const data = new FormData(e.target);
  data.append("submitLocale", i18n.locale.value);

  auth
    .postRequest("/e4/c4/case/upload", data)
    .then(() => {
      uploadEl.value.reset();
      setTimeout(() => {
        refreshMessages();
      }, 2000);
    })
    .catch((error) => {
      if (error.response?.status === 502) {
        router.push("502");
      }
    });
}
function refreshMessages() {
  refresh.value = true;
  auth
    .getRequest("/e4/c4/client/messages")
    .then((response) => {
      messages.value = response.data?.messages;
    })
    .catch((error) => {
      if (error.response.status === 502) {
        router.push("502");
      }
    })
    .finally(() => {
      // add some more spinning time so the user can catch it
      setTimeout(() => (refresh.value = false), 1000);
    });
}
function loadSettings() {
  auth
    .getRequest("/e4/c4/client/initialize")
    .then((response) => {
      settings.value = response.data;
    })
    .catch((error) => {
      if (error.response.status === 502) {
        router.push("502");
      }
    });
}
onMounted(() => {
  loadSettings();
  refreshMessages();
});
</script>

<template>
  <div>
    <div class="c4-wrap w3-content w3-padding-64 w3-mobile">
      <div class="c4-fit">
        <div class="w3-bar">
          <!-- full heading only in larger modes -->
          <div class="w3-bar-item w3-xlarge w3-hide-small">
            {{ $t("inbox.l.view") }}
          </div>
          <!-- show smaller and shorter heading on small screens -->
          <div class="w3-bar-item w3-large w3-hide-large w3-hide-medium">
            <b v-t="'inbox.l.viewShort'" />
          </div>
          <i
            class="fas fa-sync w3-large w3-bar-item w3-right w3-padding-16 w3-text-blue"
            :class="{ 'w3-spin': refresh }"
            @click.prevent="refreshMessages"
          />
        </div>
        <div
          class="w3-card"
          style="flex: 1; overflow: hidden"
        >
          <ChatControl
            :messages="messages"
            v-model="textMsg"
            :allow-audio="settings?.allowAudio"
            @submit:message="submitMessage"
            @submit:audio="submitAudio"
            ref="chatEl"
          />
        </div>

        <div class="w3-section">
          <form @submit.stop.prevent="uploadFiles">
            <div class="sy-info-text">{{ $t("caseinfo.l.uploadWarn") }}</div>

            <UploadInput
              name="file[]"
              :multiple="true"
              :max-size="100"
              ref="uploadEl"
            />

            <button
              class="w3-btn w3-right"
              type="submit"
            >
              {{ $t("inbox.btn.upload") }}
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.c4-wrap {
  padding: 0.5rem;
  overflow: hidden;
}
.c4-fit {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.c4-scroll {
  overflow: auto;
  flex: 1;
}
.c4-h100 {
  height: 100%;
}
.c4-message-item {
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 992px) {
    flex-direction: column-reverse;
  }
}
</style>
