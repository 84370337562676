<template>
  <div>
    <h1 v-t="'error.h.error502'" />
    <p v-t="'error.m.error502'" />
  </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Error502",
};
</script>

<style>
</style>