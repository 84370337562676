<script setup>
/**
 * Renders the list of existing messages in a collapsable.
 */
import { ref, watch, defineProps } from "vue";
// import Collapsable from "@/../../common/components/Collapsable.vue";
import ChatMessage from "./ChatMessage.vue";

const props = defineProps({
  /**
   * List of messages to display.
   */
  messages: Array,
  /**
   * Whether to mark external messages with a special label.
   */
  markExternal: {
    type: Boolean,
    default: false,
  },
});

// const showList = ref(true);
const messageList = ref(props.messages);

watch(
  () => props.messages,
  (val, prev) => {
    if (val?.length !== prev?.length) {
      // we cannot simply override the list of messages, otherwise
      // the animation of the new entry(ies) does not work properly.
      // insert new entries (diff) into beginning of the list
      messageList.value.splice(0, 0, ...val.slice(0, val.length - prev.length));
    }
  }
);
</script>

<template>
  <!-- <i
    class="fas fa-angle-double-down w3-right sy-btn-collapsable"
    :class="{ closed: !showList }"
    @click="showList = !showList"
  /> -->
  <div class="c4-scroll">
    <TransitionGroup
      name="msglist"
      tag="ul"
      class="w3-ul w3-input theme-input sy-comment-list"
    >
      <li
        v-for="msg in messageList"
        :key="msg"
        class="sy-chat-message"
        :class="msg.routing"
      >
        <ChatMessage
          :message="msg"
          :mark-external="markExternal"
        />
      </li>
    </TransitionGroup>
  </div>
</template>

<style lang="scss">
.sy-chat-message {
  &.received {
    background-color: var(--list-item-alt-bg);
  }
}
</style>
