<template>
  <c-4-page>
    <template v-slot:header>{{ $t("error.l.page") }}</template>

    <template v-slot:body>
      <div class="w3-container">
        <div class="w3-row-padding w3-section">
          <h4 class="w3-col" v-t="'error.m.submitError'" />
        </div>

        <div class="w3-row-padding w3-section">
          <div class="w3-col" v-t="'error.m.errorInfo'" />
        </div>
        <div class="w3-row-padding w3-section">
          <div class="w3-col" v-t="'error.m.errorThanks'" />
        </div>

        <!-- Show user a summary of what will be sumbitted -->
        <div class="w3-row-padding">
          <div class="w3-col w3-section">
            <b><label v-t="'error.l.errorSummary'" /></b>
            <span v-if="props.anonymous" v-t="'feedback.l.anonymous'" />
            <div class="w3-padding-16" ref="summary">
              <div v-for="(a, name) in props.summary" :key="name">
                <div v-if="a" class="w3-blue w3-row-padding" v-t="name" />
                <div style="white-space: pre-wrap" v-html="a" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:nav>
      <!-- Show a "print this page" button -->
      <div class="w3-padding w3-center c4-screen-only">
        <p class="w3-light-blue w3-large" v-t="'feedback.m.print'" />
        <button
          type="button"
          class="w3-btn w3-round-xxlarge w3-xxlarge w3-blue"
          @click="printPage"
        >
          <i class="fas fa-print"></i>
        </button>
      </div>
    </template>
  </c-4-page>
</template>

<script>
import C4Page from "@/components/C4Page.vue";

export default {
  components: { C4Page },
  name: "C4SubmitErrorPage",
  props: {
    props: Object,
  },
  methods: {
    printPage() {
      window.print();
    },
  },
};
</script>

<style>
</style>