import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { i18n } from './i18n';
// import { auth } from "@/plugins/auth.js"


import "@/assets/css/main.scss";

createApp(App)
	// i18n
	.use(i18n)
	// Authentication
	// .use(auth)
	// router
	.use(router)
	.mount('#app');
