<template>
  <div class="c4-login">
    <div class="w3-modal">
      <div
        class="w3-modal-content w3-card-4"
        style="min-width: 400px; max-width: fit-content"
      >
        <div class="w3-container w3-blue">
          <h3 v-t="'login.l.header'" />
        </div>
        <form>
          <div class="w3-container">
            <div class="w3-section">
              <label
                ><b>{{ $t("login.l.username") }}</b></label
              >
              <input
                class="w3-input w3-border w3-hover-border-black w3-margin-bottom"
                type="text"
                v-model="username"
                name="username"
                autofocus
                autocomplete
              />

              <label
                ><b>{{ $t("login.l.password") }}</b></label
              >
              <input
                class="w3-input w3-border w3-hover-border-black"
                type="password"
                v-model="password"
                name="password"
                autocomplete
              />

              <p
                v-show="showLoginError"
                class="w3-center w3-text-red"
                v-t="'login.m.error'"
              />

              <button
                class="w3-btn w3-col w3-blue w3-section"
                type="submit"
                @click.prevent="login"
                v-t="'common.btn.login'"
              />
            </div>
          </div>
        </form>

        <div class="w3-container w3-border-top w3-padding-16 w3-light-grey">
          <router-link
            to="/"
            class="w3-btn w3-red"
            v-t="'common.btn.cancel'"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LoginBox",
  inject: ["Session"],
  data() {
    return {
      username: null,
      password: null,
      showLoginError: false,
    };
  },
  methods: {
    login() {
      var _self = this;
      this.Session.login(this.username, this.password)
        .then(() => {
          // for now always redirect to inbox after login
          _self.$router.replace("/inbox");
        })
        .catch((error) => {
          if (error.response.status === 403) {
            _self.showLoginError = true;
          } else if (error.response.status === 502) {
            _self.$router.push("502");
          }
        })
        .finally(() => {
          _self.username = null;
          _self.password = null;
        });
    },
  },
  created() {
    // use case id from URL
    const urlParams = new URLSearchParams(window.location.search);
    this.username = urlParams.get("case");
  },
};
</script>

<style>
.c4-login > .w3-modal {
  display: block;
  position: relative;
}
</style>
