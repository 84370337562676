<template>
  <c-4-page>
    <template v-slot:header>{{ $t("submit.l.page") }}</template>

    <template v-slot:body>
      <form
        class="w3-container"
        ref="formEl"
        method="POST"
      >
        <div class="w3-container w3-justify">
          <div
            class="w3-section"
            v-t="'submit.m.info'"
          />
          <div class="w3-col">
            <div v-if="!props.email && !props.anonymous">
              <div v-t="'submit.m.noEmail'" />
              <div class="w3-section">
                <label
                  >{{ $t("userinfo.l.email") }}
                  <small> {{ $t("common.l.optional") }}</small>
                </label>
                <input
                  class="w3-input w3-border"
                  type="email"
                  name="email"
                />
              </div>
            </div>
          </div>
          <i18n-t
            class="w3-section"
            tag="div"
            keypath="submit.m.access"
            scope="global"
          >
            <strong>{{ $t("common.l.password") }}</strong>
            <strong>{{ $t("common.l.mailboxId") }}</strong>
          </i18n-t>
        </div>

        <div class="w3-row-padding">
          <div class="w3-col w3-section">
            <input
              name="caseId"
              type="text"
              :value="props.token"
              hidden
            />
            <!-- password prompt -->
            <label
              v-t="'submit.l.password'"
              for="new-password"
            />
            <PasswordInput
              id="new-password"
              name="password"
              v-model="password"
              pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
              autocomplete="new-password"
              :required="true"
              :button-title="$t('common.t.password')"
            />
          </div>
          <div class="w3-col">
            <!-- confirm password -->
            <label v-t="'submit.l.passwordConfirm'" />
            <PasswordInput
              v-model="passwordConfirm"
              :required="true"
              :button-title="$t('common.t.password')"
              :invalid="passwordError"
              @change="comparePasswords"
            />
            <div
              v-show="passwordError"
              class="w3-margin-left w3-text-red"
            >
              <b v-t="'submit.m.nomatch'" />
            </div>
          </div>
          <!-- password rules hint -->
          <div class="w3-col w3-section">
            <div v-t="'submit.l.passwordRules'" />
            <div class="w3-margin-left">
              <div
                ref="letterEl"
                class="w3-half invalid"
                v-html="$t('submit.l.passwordLetter')"
              />
              <div
                ref="capitalEl"
                class="w3-half invalid"
                v-html="$t('submit.l.passwordCapital')"
              />
              <div
                ref="numberEl"
                class="w3-half invalid"
                v-html="$t('submit.l.passwordNumber')"
              />
              <div
                ref="lengthEl"
                class="w3-half invalid"
                v-html="$t('submit.l.passwordLength')"
              />
            </div>
          </div>
        </div>

        <!-- Show user a summary of what will be sumbitted -->
        <div class="w3-container">
          <div class="w3-section">
            <b><label v-t="'submit.l.summary'" /></b>
            <div
              v-if="props.anonymous"
              v-t="'submit.l.anonymous'"
            />
            <div
              class="w3-padding-16"
              ref="summaryEl"
            >
              <div
                v-for="(a, name) in props.summary"
                :key="name"
              >
                <div
                  v-if="a"
                  class="w3-blue"
                  v-t="name"
                />
                <div
                  style="white-space: pre-wrap"
                  v-html="a"
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    </template>
    <template v-slot:nav>
      <div
        v-show="formValidationFailed"
        class="w3-margin-left w3-text-red"
      >
        <small v-t="'common.m.required'" />
      </div>

      <div class="w3-container w3-padding">
        <CheckLabel
          class="w3-section"
          v-model:checked="consent"
          :data="linkData"
          :inline="true"
          rel="noopener"
          target="_blank"
          checkbox="after"
        />
      </div>
      <button
        class="w3-btn w3-white w3-border w3-border-blue w3-ripple w3-margin"
        type="button"
        @click="$emit('page-prev', 'caseinfo')"
        v-t="'common.btn.back'"
      ></button>
      <button
        class="w3-btn w3-white w3-border w3-border-blue w3-ripple w3-margin"
        type="button"
        @click="next('robots')"
        v-t="'common.btn.submit'"
        :disabled="!consent"
      ></button>
      <button
        class="w3-btn w3-white w3-border w3-border-blue w3-ripple w3-margin w3-right w3-hide-small"
        type="button"
        @click="abort($t('submit.m.cancel'))"
        v-t="'common.btn.cancel'"
      ></button>
      <button
        class="w3-btn w3-white w3-border w3-border-blue w3-ripple w3-margin w3-right w3-hide-medium w3-hide-large"
        type="button"
        @click="abort($t('submit.m.cancel'))"
        :title="$t('submit.m.cancel')"
      >
        <i class="fas fa-times w3-large w3-text-red"></i>
      </button>
    </template>
  </c-4-page>
</template>

<script>
import CheckLabel from "@/../../common/components/Forms/CheckLabel.vue";
import C4Page from "@/components/C4Page.vue";
import PasswordInput from "@/components/PasswordInput.vue";
import { useFormValidate } from "@/utils/formvalidation.js";
import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

export default {
  components: { C4Page, PasswordInput, CheckLabel },
  name: "C4SubmitPage",
  emits: ["page-next", "page-prev"],
  props: {
    props: Object,
    referrer: String,
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const i18n = useI18n();

    const formEl = ref(null);
    const summaryEl = ref(null);
    const letterEl = ref(null);
    const capitalEl = ref(null);
    const numberEl = ref(null);
    const lengthEl = ref(null);

    const formValidationFailed = ref(false);

    const password = ref(null);
    const showPassword = ref(false);
    const passwordConfirm = ref(null);
    const showPasswordConfirm = ref(false);
    const passwordError = ref(false);
    const consent = ref(false);
    const summaryValue = ref(null);

    // try to determine the best match locale that can be found in the
    // privacy files configuration. Config is arranged by language keys.
    const localeBestMatch = computed(() => {
      // exact match
      if (
        Object.prototype.hasOwnProperty.call(
          props.props.company.privacyFiles,
          i18n.locale.value
        )
      ) {
        return i18n.locale.value;
      }
      if (
        Object.prototype.hasOwnProperty.call(
          props.props.company.privacyFiles,
          "en"
        )
      ) {
        return "en";
      }
      if (
        Object.prototype.hasOwnProperty.call(
          props.props.company.privacyFiles,
          "de"
        )
      ) {
        return "de";
      } else {
        // first match, order by lanugage keys
        const keys = Object.keys(props.props.company.privacyFiles).sort();
        return keys?.length > 0 ? keys[0] : null;
      }
    });

    const linkData = computed(() => ({
      text: t(
        `submit.l.${
          props.props.company.privacyUrl ? "consentLink" : "consentFile"
        }`,
        [t("submit.l.privacyWord")]
      ),
      uri: props.props.company.privacyUrl,
      file: props.props.company.privacyFiles
        ? {
            uri: props.props.company.privacyFiles[localeBestMatch.value]?.uri,
            name: props.props.company.privacyFiles[localeBestMatch.value]
              ?.filename,
          }
        : null,
    }));

    function comparePasswords() {
      // passwords must match
      if (password.value !== passwordConfirm.value) {
        passwordError.value = true;
      } else {
        passwordError.value = false;
      }
    }
    /**
     * Validate form data and proceed to next page
     */
    function next(page) {
      // validate form before we leave page
      if (!useFormValidate(formEl.value)) {
        formValidationFailed.value = true;
        return false;
      }

      // passwords must match
      if (password.value !== passwordConfirm.value) {
        formValidationFailed.value = true;
        return false;
      }

      // we're good
      formValidationFailed.value = false;

      summaryValue.value = summaryEl.value.innerHTML;

      emit("page-next", {
        page: page,
        data: new FormData(formEl.value),
        props: {
          password: password.value,
        },
      });
    }
    function abort(msg) {
      console.log(msg);
      let answer = confirm(msg);
      if (answer) document.location.reload();
    }
    watch(
      () => password.value,
      (val) => {
        /*
         * Validate and show password rules while user types
         */

        // Validate lowercase letters (Cyrillic)
        var lowerCaseLetters = /[a-z]|[\u0430-\u045f]/g;
        if (val.match(lowerCaseLetters)) {
          letterEl.value.classList.remove("invalid");
          letterEl.value.classList.add("valid");
        } else {
          letterEl.value.classList.remove("valid");
          letterEl.value.classList.add("invalid");
        }

        // Validate capital letters (Cyrillic)
        var upperCaseLetters = /[A-Z]|[\u0400-\u042f]/g;
        if (val.match(upperCaseLetters)) {
          capitalEl.value.classList.remove("invalid");
          capitalEl.value.classList.add("valid");
        } else {
          capitalEl.value.classList.remove("valid");
          capitalEl.value.classList.add("invalid");
        }

        // Validate numbers
        var numbers = /[0-9]/g;
        if (val.match(numbers)) {
          numberEl.value.classList.remove("invalid");
          numberEl.value.classList.add("valid");
        } else {
          numberEl.value.classList.remove("valid");
          numberEl.value.classList.add("invalid");
        }

        // Validate length
        if (val.length >= 8) {
          lengthEl.value.classList.remove("invalid");
          lengthEl.value.classList.add("valid");
        } else {
          lengthEl.value.classList.remove("valid");
          lengthEl.value.classList.add("invalid");
        }
      }
    );

    return {
      formEl,
      summaryEl,
      letterEl,
      capitalEl,
      numberEl,
      lengthEl,
      formValidationFailed,
      password,
      showPassword,
      passwordConfirm,
      showPasswordConfirm,
      passwordError,
      consent,

      // computed
      linkData,

      // functions
      comparePasswords,
      next,
      abort,
    };
  },
};
</script>

<style lang="scss">
/* Add a green text color and a checkmark when the requirements are right */
.valid {
  color: green;
  &:before {
    position: relative;
    left: -8px;
    content: "✔";
  }
}

/* Add a red text color and an "x" when the requirements are wrong */
.invalid {
  color: red;
  &:before {
    position: relative;
    left: -8px;
    content: "✖";
  }
}
</style>
