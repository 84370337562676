<script setup>
/**
 * A messaging widget with a list of messages and an editor input.
 * The modelValue is changed after the submit button (with according event) is hit.
 */
import { useModelWrapper } from "@/../../common/utils/modelWrapper.js";
import { defineEmits, defineExpose, defineProps, ref } from "vue";
import ChatInput from "./ChatInput.vue";
import MessageList from "./MessageList.vue";

const props = defineProps({
  /**
   * Preset message in editor.
   * null / undefine when empty is wanted
   */
  modelValue: String,
  /**
   * List of existing messages
   */
  messages: Array,
  /**
   * Whether user can record audio
   */
  allowAudio: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits([
  "submit:message",
  "update:modelValue",
  "submit:audio",
]);
defineExpose({
  reset,
});

const inputEl = ref(null);
const text = useModelWrapper(props, emit);

/**
 * Allow clearing input fields from external.
 */
function reset() {
  inputEl.value.reset();
}
</script>

<template>
  <div class="sy-control c4-fit">
    <MessageList
      :messages="messages"
      :mark-external="false"
    />

    <div class="w3-padding-small w3-border-top">
      <ChatInput
        v-model="text"
        :allow-audio="allowAudio"
        @submit:message="$emit('submit:message')"
        @submit:audio="$emit('submit:audio', $event)"
        ref="inputEl"
      />
    </div>
  </div>
</template>

<style lang="scss">
.msglist-move, /* apply transition to moving elements */
.msglist-enter-active,
.msglist-leave-active {
  transition: all 0.5s ease;
}

.msglist-enter-from,
.msglist-leave-to {
  opacity: 0;
  transform: translateX(-3rem);
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.msglist-leave-active {
  position: absolute;
}
</style>
