<script setup>
import { defineProps, defineExpose, ref, computed } from "vue";

const props = defineProps({
  name: String,
  multiple: {
    type: Boolean,
    default: false,
  },
  /**
   * Max upload size in MB
   */
  maxSize: {
    type: Number,
    default: 10,
  },
});

defineExpose({
  reset,
  empty,
});

const fileInput = ref(null);
const dragover = ref(false);
const files = ref([]);
const multipleError = ref(false);
const uid = ref(Math.random());

const overallFileSize = computed(() =>
  files.value?.reduce((size, f) => size + f.size, 0)
);
const remainingAmount = computed(() =>
  ((props.maxSize * 1000000 - overallFileSize.value) / 1000000).toFixed(1)
);

/**
 * Drag and drop functions.
 * Disallow dropping (and effect) when in single mode
 * but user drags multiple files (items).
 */
function dragEnter(e) {
  dragover.value = true;
  if (!canDrop(e)) {
    e.dataTransfer.dropEffect = "none";
    multipleError.value = true;
  } else {
    e.dataTransfer.dropEffect = "copy";
  }
}
/**
 * Drag leave when leaving the drop zone
 */
function dragLeave() {
  dragover.value = false;
  multipleError.value = false;
}
/**
 * Drag leave when leaving the error overlay.
 * Need some special handling here, because a leave event
 * is fired when moving over the error text.
 */
function dragLeaveError(e) {
  if (e.target.contains(e.fromElement)) {
    // leave event came from leaving to one of our children
    e.stopPropagation();
  } else {
    // leaving (to) drop zone
    dragover.value = false;
    multipleError.value = false;
  }
}
function dragOver(e) {
  e.preventDefault();

  if (!canDrop(e)) {
    e.dataTransfer.dropEffect = "none";
  } else {
    e.dataTransfer.dropEffect = "copy";
  }
}
/**
 * File input via drag and drop
 */
function filesDropped(e) {
  e.preventDefault();
  e.stopPropagation();
  dragover.value = false;
  // if not multi select, prevent dropping of multiple files
  if (!canDrop(e)) {
    e.dataTransfer.dropEffect = "none";
    multipleError.value = false;
    return;
  }

  e.dataTransfer.dropEffect = "copy";

  const droppedFiles = e.dataTransfer.files;
  // populate form element
  fileInput.value.files = droppedFiles;

  if (droppedFiles) {
    [...droppedFiles].forEach((f) => {
      files.value.push(f);
    });
  }
}
/**
 * File input via browse
 */
function filesChanged(e) {
  const selectedFiles = e.target.files;
  if (selectedFiles) {
    [...selectedFiles].forEach((f) => {
      files.value.push(f);
    });
  }
}
function removeFile(index) {
  files.value.splice(index, 1);
}
function canDrop(e) {
  return (
    props.multiple ||
    (e.dataTransfer.items.length === 1 && files.value.length === 0)
  );
}
/**
 * Allow clearing input fields from external.
 */
 function reset() {
  files.value = [];
  fileInput.value.value = null;
}
/**
 * Verifies if control contains files.
 * 
 * @returns Whether files are empty
 */
function empty() {
  return files.value?.length === 0;
}
</script>

<template>
  <div class="sy-upload-body">
    <div
      class="sy-drop-container"
      @dragenter.prevent="dragEnter"
    >
      <div
        v-show="dragover"
        class="sy-dropzone"
        @drop.prevent="filesDropped"
        @dragover.prevent="dragOver"
        @dragleave.prevent="dragLeave"
      >
        <div
          @dragleave="dragLeaveError"
          @dragleave.capture.stop
          class="sy-upload-error"
          v-show="multipleError"
        >
          <div class="sy-error-text center">
            {{ $t("types.upload.m.singleFile") }}
          </div>
        </div>
      </div>
      <div class="sy-browse-zone">
        <label
          :for="`file_${uid}`"
          style="cursor: pointer"
        >
          <div style="font-size: 200%">
            <i class="fas fa-cloud-upload-alt w3-text-grey" />
          </div>
          <div>
            {{ $t("types.upload.l.select") }}
          </div>
          <div v-if="overallFileSize <= 0">
            {{ $t("types.upload.m.uploadSize", [maxSize]) }}
          </div>
          <div
            v-else-if="remainingAmount <= 0"
            class="w3-text-red"
          >
            <b>{{ $t("error.m.uploadSize", [100]) }}</b>
          </div>
          <div v-else>
            {{ $t("types.upload.m.remaining", [remainingAmount]) }}
          </div>
        </label>
        <input
          type="file"
          :name="name"
          :id="`file_${uid}`"
          :multiple="multiple"
          :invalid="remainingAmount < 0"
          hidden
          @change="filesChanged"
          ref="fileInput"
        />
      </div>

      <div class="sy-file-container">
        <span
          v-for="(entry, index) of files"
          :key="index"
        >
          {{ entry.name }}
          <button
            class="sy-btn-remove"
            style="font-size: smaller"
            @click.prevent="removeFile(index)"
          >
            <i class="far fa-trash-alt"></i>
          </button>
        </span>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.sy-upload-body {
  flex: 1 0 auto;
  padding: 0.5rem;
}
.sy-drop-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  width: 100%;
  border: 2px dashed lightsteelblue;
  border-radius: 0.25rem;
  position: relative;
}
.sy-browse-zone {
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  padding-bottom: 0.5rem;
}
.sy-dropzone {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  visibility: visible;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 0.25rem;
}
.sy-file-input-hidden {
  position: absolute;
  height: 0;
  width: 0;
  opacity: 0;
}
.sy-file-container {
  // flex: 1 0 auto;
  max-height: 12rem;
  overflow: auto;
  padding: 0.5rem;
  line-height: 2;
}
.sy-upload-error {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #ffffffaa;
  backdrop-filter: blur(3px);
  text-align: center;
  z-index: 1;
}
.center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.sy-btn-remove {
  font-size: smaller;
  border: none;
  padding: 0;
  // vertical-align: text-bottom;
  cursor: pointer;
  margin-right: 0.75rem;
}
.sy-btn-text {
  background-color: transparent;
  border: 0;
  color: var(--item-color);
}
.sy-error-text {
  color: var(--item-color);
  font-weight: bold;
  font-size: 120%;
}
</style>
>
