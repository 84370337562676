<script setup>
import { computed, h, toRefs } from "vue";

const emit = defineEmits(["click:download"]);
const props = defineProps({
  text: String,
  linkUri: String,
});
const { text, linkUri } = toRefs(props);

const replaceLinks = computed(() => {
  const parts = text.value?.split(/\$link\b{(.*?)}/);

  const placeholder = [
    parts?.map((el, idx) => {
      return idx === 1
        ? h("a", { href: linkUri.value, rel: "noreferrer", target: "_blank" }, el)
        : replaceFilePlaceholder.value(el);
    }),
  ];

  return h("div", placeholder);
});

const replaceFilePlaceholder = computed(() => (str) => {
  const parts = str.split(/\$file\b{(.*?)}/);

  const placeholder = [
    parts?.map((el, idx) => {
      return idx === 1
        ? h("span", { class: "sy-clickable-text", onClick() {
          emit("click:download")} }, el)
        : h("span", {}, el);
    }),
  ];

  return placeholder;
});
</script>

<template>
  <replaceLinks />
</template>

<style lang="scss">
.sy-clickable-text {
  cursor: pointer;
  text-decoration: underline;
}
</style>
