/**
 * Validate the given form
 *
 * @param {Form} form
 * @returns
 */
export function useFormValidate(form) {
  const valid = form?.checkValidity();
  const fields = form?.querySelectorAll('.invalid, :invalid, [invalid="true"]');

  if (!valid || fields?.length > 0) {
    form.classList.add("c4-invalid");
  } else {
    form.classList.remove("c4-invalid");
  }

  return valid && fields?.length === 0;
}
