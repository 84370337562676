<template>
  <c-4-page>
    <div class="w3-container w3-blue">
      <h2></h2>
    </div>
    <template v-slot:header>{{ $t("userinfo.l.page") }}</template>

    <template v-slot:body>
      <form
        class="w3-container w3-margin-top"
        ref="form"
      >
        <div class="w3-row-padding w3-section">
          <div class="w3-quarter">
            <label v-t="'userinfo.l.salutation'" />
            <select
              class="w3-select w3-border"
              name="salutation"
              v-model="salutation"
            >
              <option selected></option>
              <option
                value="userinfo.opt.salutationFemale"
                v-t="'userinfo.opt.salutationFemale'"
              ></option>
              <option
                value="userinfo.opt.salutationMale"
                v-t="'userinfo.opt.salutationMale'"
              ></option>
            </select>
          </div>
        </div>

        <div class="w3-row-padding w3-section">
          <div class="w3-col">
            <label>
              {{ $t("userinfo.l.lastname") }}
              <small> {{ $t("common.l.required") }}</small>
            </label>
            <input
              class="w3-input w3-border"
              type="text"
              name="lastname"
              v-model="lastname"
              required
            />
          </div>
          <div class="w3-col">
            <label v-t="'userinfo.l.firstname'" />
            <input
              class="w3-input w3-border"
              type="text"
              name="firstname"
              v-model="firstname"
            />
          </div>
        </div>

        <div class="w3-row-padding w3-section">
          <div class="w3-col">
            <label v-t="'userinfo.l.email'" />
            <input
              class="w3-input w3-border"
              type="email"
              name="email"
              v-model="email"
            />
          </div>
        </div>

        <div class="w3-row-padding w3-section">
          <div class="w3-col">
            <label v-t="'userinfo.l.address'" />
            <textarea
              class="w3-input w3-border"
              rows="4"
              style="resize: none"
              name="address"
            />
          </div>
        </div>

        <div class="w3-row-padding w3-section">
          <div class="w3-col">
            <label v-t="'userinfo.l.phone'" />
            <input
              class="w3-input w3-border"
              type="text"
              name="phone"
            />
          </div>
        </div>

        <input
          type="radio"
          name="identified"
          value="true"
          checked
          hidden
        />
      </form>
    </template>
    <template v-slot:nav>
      <div
        v-show="formValidationFailed"
        class="w3-margin-left w3-text-red"
      >
        <small v-t="'common.m.required'" />
      </div>

      <button
        class="w3-btn w3-white w3-border w3-border-blue w3-ripple w3-margin"
        type="button"
        @click="$emit('page-prev', 'general')"
        v-t="'common.btn.back'"
      ></button>
      <button
        class="w3-btn w3-white w3-border w3-border-blue w3-ripple w3-margin"
        type="button"
        @click="next('caseinfo')"
        v-t="'common.btn.next'"
      ></button>
    </template>
  </c-4-page>
</template>

<script>
import C4Page from "@/components/C4Page.vue";
import C4Validation from "@/components/C4Validation.vue";
import C4Summary from "@/components/C4Summary.vue";

export default {
  components: { C4Page },
  name: "C4UserInfoPage",
  mixins: [C4Validation, C4Summary],
  emits: ["page-next", "page-prev"],
  props: {
    props: Object,
    referrer: String,
  },
  data() {
    return {
      salutation: null,
      firstname: null,
      lastname: null,
      email: null,
    };
  },
  methods: {
    /**
     * Validate form data and proceed to next page
     */
    next(page) {
      // validate form before we leave page
      if (!this.validate(this.$refs.form)) return false;

      let name = [];
      if (this.salutation) name.push(this.$t(this.salutation));
      if (this.firstname) name.push(this.firstname);
      if (this.lastname) name.push(this.lastname);

      const data = new FormData(this.$refs.form);
      this.$emit("page-next", {
        page: page,
        data: data,
        summary: this.summary(data, {
          name: {
            value: name.join(" "),
          },
          address: "userinfo.l.address",
          phone: "userinfo.l.phone",
          email: "userinfo.l.email",
        }),
        props: {
          email: this.email,
        },
      });
    },
  },
};
</script>
