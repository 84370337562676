<script setup>
/**
 * Editor part of the messaging widget
 */
import { ref, watch, defineProps, defineEmits, defineExpose } from "vue";
import AudioInput from "../AudioInput.vue";

const emit = defineEmits([
  "update:modelValue",
  "submit:message",
  "submit:audio",
]);
const props = defineProps({
  modelValue: String,
  /**
   * Whether user can record audio
   */
  allowAudio: {
    type: Boolean,
    default: false,
  },
});
defineExpose({
  reset,
});

const message = ref(null);
const audioEl = ref(null);
const isAudio = ref(false);
const audio = ref(null);

/**
 * Emit events for the changed model as well as the submit button was hit.
 */
async function submit() {
  if (isAudio.value) {
    // check for existing audio

    // voice input is required
    if (!audio.value) {
      // nothing there yet, maybe the user has not stopped the recording
      try {
        // try to acquire audio
        await audioEl.value?.acquireRecording();
      } catch {
        // no audio, do not submit anything
        reset();
        return false;
      }
    }
    let data = new FormData();
    data.set("audio", audio.value, "audio.webm");

    emit("submit:audio", data);
  } else {
    // check for text
    const text = message.value.innerText.trim();
    if (text?.length > 0) {
      emit("update:modelValue", text);
      emit("submit:message");
    }
  }
}
/**
 * Allow clearing input fields from external.
 */
function reset() {
  audio.value = null;
  isAudio.value = false;
  if (message.value) message.value.innerText = null;
}

watch(
  () => props.modelValue,
  (val) => {
    message.value.innerText = val;
  }
);
</script>

<template>
  <div
    class="sy-chat-editor"
    tabindex="-1"
    @focus="message?.focus()"
  >
    <!-- capture and stop input, otherwise parent will be erroneously updated -->
    <div
      v-if="!isAudio"
      class="sy-chat-input w3-padding"
      contenteditable="true"
      tabindex="-1"
      :placeholder="$t('common.t.chatInput')"
      @input.stop
      ref="message"
    />
    <AudioInput
      v-else
      class="w3-border w3-padding"
      style="height: 10rem"
      v-model="audio"
      ref="audioEl"
    />

    <div
      v-if="allowAudio"
      class="sy-chat-toggle w3-btn w3-padding-small"
      role="button"
      @click="isAudio = !isAudio"
      tabindex="0"
    >
      <span v-if="isAudio">
        <i class="fas fa-signature w3-margin-right" />
        <span>{{ $t("common.btn.text") }}</span>
      </span>
      <span v-else>
        <i class="fas fa-microphone-alt w3-margin-right" />
        <span>{{ $t("common.btn.audio") }}</span>
      </span>
    </div>
    <div
      class="sy-chat-submit w3-btn w3-padding-small"
      role="button"
      @click="submit"
      tabindex="0"
    >
      {{ $t("common.btn.sendMessage") }}
    </div>
  </div>
</template>

<style lang="scss">
div[contenteditable="true"]:empty::before {
  // this will take the placeholder attribute of the div element and use as content
  content: attr(placeholder);
  color: #888;
}
.sy-chat-editor {
  position: relative;
  padding: 0.25rem 0.25rem 2.5rem;
  &:focus-within {
    outline: none;
    box-shadow: var(--input-focus);
  }
  > .sy-chat-input {
    &:focus {
      outline: none;
    }
  }
  > .sy-chat-submit {
    position: absolute;
    right: 0;
    bottom: 0;
    margin: 0.25rem;
  }
  > .sy-chat-toggle {
    position: absolute;
    left: 0;
    bottom: 0;
    margin: 0.25rem;
  }
}
</style>
