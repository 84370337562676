<template>
  <div class="c4-app">
    <header class="w3-bar c4-header">
      <div class="c4-text-chrome c4-logo">
        <router-link
          :to="{
            path: refId ? '/' : '/inbox',
            query: { refId: refId ? refId : undefined },
          }"
          style="text-decoration: none"
        >
          <img
            id="logo_image"
            src="../assets/sycobase-app.png"
            height="35"
          />
          <span class="c4-logo-text"> WBS</span>
        </router-link>
      </div>
      <router-link
        v-if="loggedin"
        to="/logout"
        class="c4-text-chrome w3-margin-right"
        style="font-size: 1.5rem; align-self: center"
      >
        <i
          class="fas fa-sign-out-alt"
          :title="$t('app.t.logout')"
        />
      </router-link>
      <div class="c4-lang-select">
        <select
          v-model="$i18n.locale"
          class="w3-select w3-border-0 c4-chrome c4-text-chrome"
          style="height: 100%; outline: none"
          @change="handleLocaleChange"
        >
          <option
            value="bg"
            v-html="$t('app.lang.bg')"
          ></option>
          <option
            value="de"
            v-html="$t('app.lang.de')"
          ></option>
          <option
            value="en"
            v-html="$t('app.lang.en')"
          ></option>
          <option
            value="es"
            v-html="$t('app.lang.es')"
          ></option>
          <option
            value="fr"
            v-html="$t('app.lang.fr')"
          ></option>
          <option
            value="it"
            v-html="$t('app.lang.it')"
          ></option>
          <option
            value="sl"
            v-html="$t('app.lang.sl')"
          ></option>
        </select>
      </div>
    </header>
    <router-view class="c4-app-body" />
    <footer class="w3-bar c4-footer">
      <div
        class="w3-container c4-text-chrome"
        style="font-size: small; flex: 1"
      >
        <span>© 2024, </span>
        <img
          height="10"
          src="../assets/sycobase-app-nc.png"
          style="vertical-align: baseline"
        />
      </div>
      <div class="w3-container c4-text-chrome w3-small">
        <a
          href="https://www.sycobase.de/impressum"
          target="_blank"
          rel="noopener"
          v-t="'app.l.imprint'"
        />
        |
        <a
          href="https://www.sycobase.de/datenschutz"
          target="_blank"
          rel="noopener"
          v-t="'app.l.privacy'"
        />
      </div>
    </footer>
  </div>
</template>

<script>
import {
  i18n,
  setI18nLanguage,
  loadLocaleMessages /*SUPPORT_LOCALES*/,
  matchLocale,
} from "@/i18n";
import authXref from "@/authXref.js";
import auth from "@/auth.js";

export default {
  name: "AppLayout",
  data() {
    return {
      loggedin: false,
      timeout: 0,
      sessionTimer: null,
      refId: null,
    };
  },
  provide() {
    return {
      Auth: authXref,
      Session: auth,
    };
  },
  methods: {
    handleLocaleChange() {
      // load locale messages
      loadLocaleMessages(i18n, this.$i18n.locale);

      // set i18n language
      setI18nLanguage(i18n, this.$i18n.locale);
    },
  },
  watch: {
    timeout(val) {
      if (val > 0) {
        if (this.sessionTimer) clearTimeout(this.sessionTimer);
        this.sessionTimer = setTimeout(() => {
          this.timeout--;
        }, 1000);
      } else {
        if (this.sessionTimer) clearTimeout(this.sessionTimer);
      }
    },
  },
  created() {
    // setup locale
    this.$i18n.locale = matchLocale(navigator.languages);

    // track logged in state
    auth.onChange = (loggedIn) => {
      this.loggedin = loggedIn;
    };
    auth.onSessionLifetime = (timeout) => {
      this.timeout = timeout;
    };
    auth.onSessionTimeout = () => {
      this.timeout = 0;
      // send to login
      this.$router.push("/login");
    };

    // setup referrer for sub components
    const urlParams = new URLSearchParams(window.location.search);
    this.refId = urlParams.get("refId");
  },
};
</script>
