<template>
  <c-4-page>
    <template v-slot:header>{{ $t("robots.l.page") }}</template>

    <template v-slot:body>
      <div class="w3-container w3-padding w3-padding-24 w3-center">
        <div v-t="'robots.m.info'" />

        <Captcha class="w3-margin" ref="captcha" />

        <div>
          {{ message }}
        </div>

        <button
          class="w3-btn w3-white w3-border w3-border-blue w3-ripple w3-margin"
          type="button"
          @click="$emit('page-prev', 'submit')"
          v-t="'common.btn.back'"
        >
          Zurück
        </button>
        <button
          class="w3-btn w3-white w3-border w3-border-blue w3-ripple w3-margin"
          type="button"
          @click="validate"
          v-t="'common.btn.human'"
        ></button>
      </div>
    </template>
  </c-4-page>
</template>

<script>
import C4Page from "@/components/C4Page.vue";
import Captcha from "@/components/Captcha.vue";

export default {
  components: { C4Page, Captcha },
  name: "C4NoRobotsPage",
  props: {
    props: Object,
    referrer: String,
  },
  data() {
    return {
      message: null,
    };
  },
  methods: {
    validate() {
      if (this.$refs.captcha.validateCode()) {
        this.message = this.$t("robots.m.human");
        this.$emit("submit", "feedback");
      } else {
        this.message = this.$t("robots.m.nohuman");
        return false;
      }
    },
  },
};
</script>

<style>
</style>