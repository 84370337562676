<template>
  <teleport to="body">
    <div v-if="active" class="sy-spinner">
      <svg class="spinner" viewBox="0 0 20 20">
        <circle
          class="path"
          cx="10"
          cy="10"
          r="8"
          fill="none"
          stroke-width="1"
        ></circle>
      </svg>
    </div>
  </teleport>
</template>

<script>
/* eslint-disable no-console */

export default {
  name: "BusySpinner",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    delay: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      active: false,
      timeout: null,
    };
  },
  watch: {
    show(val) {
      if (val) {
        if (this.delay > 0) {
          this.timeout = setTimeout(() => (this.active = true), this.delay);
        } else {
          this.active = true;
        }
      } else {
        if (this.timeout) clearTimeout(this.timeout);
        this.active = false;
      }
    },
  },
};
</script>

<style lang="scss">
.sy-spinner {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #ffffffee;
  text-align: center;
  z-index: 100;
  @supports (backdrop-filter: none) {
    background-color: #ffffffaa;
    backdrop-filter: blur(3px);
  }
}
.spinner {
  -webkit-animation: rotate 2s linear infinite;
  animation: rotate 2s linear infinite;
  z-index: 2;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -40px 0 0 -40px;
  width: 75px;
  height: 75px;
  & .path {
    stroke: #26d3ff;
    stroke-linecap: round;
    -webkit-animation: dash 1.5s ease-in-out infinite;
    animation: dash 1.5s ease-in-out infinite;
  }
}

@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes dash {
  0% {
    stroke-dasharray: 1, 70;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 70;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 70;
    stroke-dashoffset: -50;
  }
}
@keyframes dash {
  0% {
    stroke-dasharray: 1, 70;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 70;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 70;
    stroke-dashoffset: -50;
  }
}
</style>