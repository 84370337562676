<script setup>
import { computed, inject, toRefs, onUnmounted, ref } from "vue";
import TextWithButton from "./TextWithButton.vue";

const emit = defineEmits(["update:checked", "error:download"]);
/**
 * Object representing the file descriptor with
 * uri: uri of link
 * text: text to display
 * file: {
 *    uri: uri of resource
 *    name: the filename for download
 * }
 */

const props = defineProps({
  data: Object,
  checked: Boolean,
  inline: Boolean,
  target: String,
  rel: String,
  checkbox: {
    type: String,
    default: "after",
    validator: (val) => {
      return ["after", "before"].includes(val);
    },
  },
});
const { data } = toRefs(props);

const Auth = inject("Auth");

// track blobs so we can free up resources at unmount
// required because we cannot revokeObjectURL in the download handler
//  FF at least since 118 is failing with Access error
const downloadedBlobs = ref([]);

// handle check state and emit event
const checkState = computed({
  get: () => props.checked,
  set: (val) => {
    emit("update:checked", val);
  },
});

function downloadFile() {
  Auth.get(data.value?.file?.uri, true)
    .then((response) => {
      const href = URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = href;
      link.target = props.target;
      link.rel = props.rel;
      if (!props.inline) {
        link.setAttribute("download", data.value?.file?.name);
      }
      link.click();
      // track for cleanup
      downloadedBlobs.value.push(href);
    })
    .catch((error) => {
      console.log(error);
      emit("error:download", error.response?.status);
      // router.replace({
      //   name: "Error",
      //   params: { code: error.response?.status || 500 },
      // });
    });
}
/**
 * cleanup blobs
 */
onUnmounted(() => {
  downloadedBlobs.value.forEach((blob) => {
    URL.revokeObjectURL(blob);
  });
});
</script>

<template>
  <div style="display: flex">
    <label v-if="checkbox === 'before'">
      <input
        type="checkbox"
        required
        v-model="checkState"
      />
    </label>

    <TextWithButton
      style="margin: 0 0.5rem; text-align: justify; white-space: pre-wrap"
      :text="data?.text"
      :link-uri="data?.uri"
      @click:download="downloadFile"
    />

    <label v-if="checkbox === 'after'">
      <input
        type="checkbox"
        required
        v-model="checkState"
      />
    </label>
  </div>
</template>
