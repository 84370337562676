import { useI18n } from "vue-i18n";

/**
 * Format a "ago" time string
 */
export function useTimeSince(date) {
  const { t } = useI18n();

  var seconds = Math.floor(new Date().getTime() / 1000 - date);
  var interval = seconds / 31536000;

  if (interval > 1) {
    return t("date.ago.text", [t("date.ago.year", Math.floor(interval))]);
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return t("date.ago.text", [t("date.ago.month", Math.floor(interval))]);
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return t("date.ago.text", [t("date.ago.day", Math.floor(interval))]);
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return t("date.ago.text", [t("date.ago.hour", Math.floor(interval))]);
  }
  interval = seconds / 60;
  if (interval > 1) {
    return t("date.ago.text", [t("date.ago.minute", Math.floor(interval))]);
  }
  return t("date.ago.text", [t("date.ago.second", Math.floor(seconds))]);
}

/**
 * Format a unix timestamp to a locale dependant date string
 * @param {Number} date Timestamp as unixtime
 * @returns Formatted date string for current locale
 */
export function useFormatDate(date) {
  if (date === undefined || date === null) return null;

  const d = isNaN(date) ? new Date(date) : new Date(date * 1000);
  return date
    ? d.toLocaleDateString(undefined, {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      })
    : null;
}

/**
 * Format a unix timestamp to a locale dependant date and time string
 * @param {Number} date Timestamp as unixtime
 * @returns Formatted date and time string for current locale
 */
export function useFormatDateTime(date) {
  if (date === undefined || date === null) return null;

  const options = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  };
  return isNaN(date)
    ? new Date(date).toLocaleString(undefined, options)
    : new Date(date * 1000).toLocaleString(undefined, options);
}

/**
 * Format a unix timestamp to a locale dependant date and time string
 * @param {Number} date Timestamp as unixtime
 * @returns Formatted date and time string for current locale
 */
export function useFormatDateTimeCompact(date, seconds = true) {
  return date
    ? new Date(date * 1000).toLocaleString(undefined, {
        day: "2-digit",
        month: "2-digit",
        year: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: seconds ? "2-digit" : undefined,
      })
    : null;
}

/**
 * Format a time string to a locale dependant time string
 * @param {String} timeStr Time string as received from input of typr time
 * @returns Formatted time string for current locale
 */
export function useFormatTime(timeStr) {
  return timeStr
    ? new Date(`1970-01-01 ${timeStr}`).toLocaleTimeString(undefined, {
        timeStyle: "short",
      })
    : null;
}

/**
 * Transform date into string that a date control accepts
 *
 * @param {Number} unixtime A unix timestamp to format
 * @returns A string that can be used in a date input
 */
export function useFormatUnixdateToDateInput(unixtime) {
  const date = new Date(unixtime ? unixtime * 1000 : undefined);
  return isNaN(date)
    ? undefined
    : date.getFullYear() +
        "-" +
        String(date.getMonth() + 1).padStart(2, "0") +
        "-" +
        String(date.getDate()).padStart(2, "0");
}

/**
 * Convert a date input field value into a unix timestamp.
 *
 * @param {String} date A date string from a date input field.
 * @returns The unix timestamp for the given date string.
 */
export function useInputToUnixtime(date) {
  return Math.round(new Date(date) / 1000);
}

/**
 * Convert a Date into a string that can be used in a input filed with datetime-local type.
 *
 * @param {Date} date
 * @returns A string that can be used in a input filed with datetime-local type.
 */
export function useConvertToDateTimeLocaleString(date) {
  if (!date) return null;

  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");

  return `${year}-${month}-${day}T${hours}:${minutes}`;
}

/**
 * Determine the class used to format the urgency for the date.
 *
 * @param {Number} date as unixtimestamp
 * @returns A class string
 */
export function useUrgencyClass(date) {
  let clazz = "date-badge";
  if (!date) {
    return clazz;
  }

  const dcmp = new Date(date * 1000);
  let d = new Date();
  d.setHours(0, 0, 0);
  let dwarn = new Date(d);
  dwarn.setDate(dwarn.getDate() + 4);
  if (d.getTime() >= dcmp) {
    clazz += " danger";
  } else if (dwarn.getTime() >= dcmp) {
    clazz += " warning";
  }
  return clazz;
}
